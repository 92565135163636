<template>
    <div class="exhEdit">
        <Header ref="head" id="head" :type="headerType" @openUpgrade="upgradeToggle=true" @openIntoEdit="openIntoEdit" @saveScene="saveScene" :editingSceneId="editingSceneId"/> 
        <VendorIntroEdit v-if="introForm" @closeIntro="closeIntro" :activeTag="activeTag" @getEditng="getEditng"/>
        <LoadingSaving v-show="loadAni"/>
        <LoadingProgress v-show="loading" :loadingValue="loadedAssets/neededAssets * 100" />
        <SellerUpgrade v-if="upgradeToggle" @closeUpgrade="upgradeToggle=false"/>
        <PopAlert v-if="popalert" @closeAlert="closeAlert" :type="popAlertType" @confirm="confirm" />
        <div class="bg" v-if="editModelPop">
            <div class="uploadModelPop">
                <img @click="editModelPop=false" class="btn" src="@/assets/img/icon/cross_black.svg" alt="">
                <h4>Upload model successfully !</h4>
                <p>Please edit model’s info</p>
                <p>( you can also edit later )</p>
                <div class="inputWrap">
                    <label for="modelname">Model name</label>
                    <input type="text" id="modelname" name="modelname" placeholder="Enter your model name" v-model="editingModelName">
                </div>    
                <div class="inputWrap">
                    <label for="modelshare">Model share</label>
                    <input type="checkbox" id="modelshare" name="modelshare" v-model="editingModelShare"  :true-value="1" :false-value="0">'
                </div>    
                <div class="container">
                    <img src="@/assets/img/uploadPreview.svg" alt="">
                    <div class="uploadModelImg">
                        <label for="upload-model-input" id="upload-model-photo" :style="{backgroundImage: 'url(' + uploadModelDefault + ')'}"></label>
                        <input type="file" @change="modelUploadImageChange" id="upload-model-input">
                    </div>
                </div>
                <div class="btnWrap">
                    <button class="cancel" @click="cancelUploadModel">Cancel</button>
                    <button class="submit" @click="uploadModelShowImg">Save</button>
                </div>
            </div>
        </div>
<!-- ------------------------------------------------------------------------------------------------------------------- -->
        <div class="bg" v-if="uploadImgPop">
            <div class="uploadModelPop">
                <img @click="uploadImgPop=false" class="btn" src="@/assets/img/icon/cross_black.svg" alt="">
                <ul id="data-list">
                    <li v-for="item in licenceData">
                        <h2>{{item.title}}</h2>
                        <img src="item.img_url">
                        <button class="button" @click="changeImg(item.img_url)">Use</button>
                    </li>
                </ul>
            </div>
        </div>
<!-- ------------------------------------------------------------------------------------------------------------------- -->
        <div class="main">
            <canvas id="renderCanvas"></canvas>
        <!-- <el-form class="setBgc">
            <el-select v-model="bgc" placeholder="" :popper-class="'bgcSelect'">
                <el-option v-for="item in bgcData" :label="item.name" :value="item.value" :key="item.bgcKey"></el-option>
            </el-select>
        </el-form> -->
        <div class="left-bottom-bar" id='left-bottom-bar'>
            <div class="slider">
                <i class="el-icon-minus"></i>
                <el-slider
                :value='cameraRadius'
                vertical
                height="130px" :max=60 :min=6 @input="changeCameraRadius" show-tooltip>
                </el-slider>
                <i class="el-icon-plus"></i>
            </div>
        </div>
        <div class="modelController">
            <ul>
                <li @click="changeGizmo('move')" :class="{active:editModelType=='move'}">
                    <img src="@/assets/img/icon/move.svg" alt="">
                    <img src="@/assets/img/icon/moveA.svg" alt="">
                </li>
                <li @click="changeGizmo('scale')" :class="{active:editModelType=='scale'}">
                    <img src="@/assets/img/icon/scale.svg" alt="">
                    <img src="@/assets/img/icon/scaleA.svg" alt="">
                </li>
                <li @click="changeGizmo('rotate')" :class="{active:editModelType=='rotate'}">
                    <img src="@/assets/img/icon/rotate.svg" alt="">
                    <img src="@/assets/img/icon/rotateA.svg" alt="">
                </li>
                <!-- <li @click="editModelFunction">
                    <img src="@/assets/img/icon/edit.svg" alt="">
                    <img src="@/assets/img/icon/editA.svg" alt="">
                </li> -->
                <li @click="deleteModel">
                    <img src="@/assets/img/icon/delete.svg" alt="">
                    <img src="@/assets/img/icon/deleteA.svg" alt="">
                </li>
            </ul>
            <div class="editIinput" :class="{active:editInputToggle}">
                <h4>Transform<span class="arrow btn" @click="editInputToggle=!editInputToggle"></span></h4>
                <transition name="el-zoom-in-top">
                <div class="editInputWrap" v-show="editInputToggle">
                    <h5>Position</h5>
                    <div class="editInputContainer">
                        <div class="item">
                            <p>X</p>
                            <input type="number" v-model="position.x" v-on:change="signalChange('positionX',position.x)">
                        </div>
                        <div class="item">
                            <p>Y</p>
                            <input type="number" v-model="position.y" v-on:change="signalChange('positionY',position.y)">
                        </div>
                        <div class="item">
                            <p>Z</p>
                            <input type="number" v-model="position.z" v-on:change="signalChange('positionZ',position.z)">
                        </div>
                    </div>
                    <h5>Rotation</h5>
                    <div class="editInputContainer">
                        <div class="item">
                            <p>X</p>
                            <input type="number" v-model="rotation.x" v-on:change="signalChange('rotationX',rotation.x)">
                        </div>
                        <div class="item">
                            <p>Y</p>
                            <input type="number" v-model="rotation.y" v-on:change="signalChange('rotationY',rotation.y)">
                        </div>
                        <div class="item">
                            <p>Z</p>
                            <input type="number" v-model="rotation.z" v-on:change="signalChange('rotationZ',rotation.z)">
                        </div>
                    </div>
                    <h5>Scale</h5> 
                    <div class="editInputContainer">
                        <div class="item">
                            <p>X</p>
                            <input type="number" v-model="scale.x" v-on:change="signalChange('scaleX',scale.x)">
                        </div>
                        <div class="item">
                            <p>Y</p>
                            <input type="number" v-model="scale.y" v-on:change="signalChange('scaleY',scale.y)">
                        </div>
                        <div class="item">
                            <p>Z</p>
                            <input type="number" v-model="scale.z" v-on:change="signalChange('scaleZ',scale.z)">
                        </div>
                    </div>
                </div>
                </transition>
            </div>
                <div class="editComponent active" v-if="popEdit" >
                    <h4 v-if="editingModel.modelType == 'Monitor'|| editingModel.modelType == 'Board'">Edit <span class="arrow btn"></span></h4>
                    <div style="" class="videoLink" v-if="editingModel.modelType == 'Monitor'|| editingModel.modelType == 'Board'">
                       <!--  <p><span v-if="menuActive==1">Video</span> link <span v-if="menuActive==1">(Youtube)</span></p> -->
                      <!--  <p v-if="editingModel.modelType == 'Monitor'">Video link</p>
                        <input class="inputText" v-if="editingModel.modelType == 'Monitor'" type="text" v-model="youtubeLink" @input="changeModel"> -->
                        <p v-if="editingModel.modelType == 'Board' || editingModel.modelType == 'Monitor'">WebSite Link or Video Link</p>
                        <input class="inputText" v-if="editingModel.modelType == 'Board' || editingModel.modelType == 'Monitor'" type="text" v-model="websiteLink" @input="changeModel">
                    </div>
                    <div class="mediaPreview" v-if="editingModel.modelType == 'Monitor'|| editingModel.modelType == 'Board'">
                        <!-- <p>Preview photo</p> -->
                        <div style="display:none;" class="uploadWrap" v-if="uploadType=='upload'">
                            <label for="uploadModelImg1" class="uploadBtn btn"><img src="@/assets/img/uploadPhoto.svg" alt=""></label>
                            
                        <input type="file" @change="modelImageChange" id="uploadModelImg1" name="uploadModelImg1">
                            <!-- <p>Recommended size is 500 x 500 pixels</p> -->
                        </div>
                        <p v-if="editingModel.modelType == 'Board' || editingModel.modelType == 'Monitor'">Img link</p>
                        <input class="inputText" v-if="editingModel.modelType == 'Board' || editingModel.modelType == 'Monitor'" type="text" v-model="imgLink" @input="changeImgurl">
                        <div class="uploadImgPreview" v-if="uploadType=='preview'">
                            <div class="preview" :style="{backgroundImage: 'url(' + modelImgPreview + ')'}"></div>
                            <!-- <p @click="uploadType='upload'"><span></span>Replace</p> -->
                        </div>
<!-- ------------------------------------------------------------------------------------------------------------------- --><!-- 
                        <div>
                            <button @click="chooseImg">Choose Img</button>
                        </div> -->
<!-- ------------------------------------------------------------------------------------------------------------------- -->
                    </div>
                    <div class="colorMateriral" v-if="editingModel.modelType!='Customize'&&editingModel.modelType!='Avatar'" >
                        <p>Material <span class="arrow btn" @click="editInputToggle=!editInputToggle"></span></p>
                        <div class="colorWrap">
                            <div class="item" :class="{active:index == seletedMaterial.index}" :key="'material'+index" v-for="(item,index) in colorData" @click="seletMaterial(item,index,'asyncModle')" :style="{backgroundImage: 'url(' + item + ')'}"></div>
                            <!-- <div style="position:relative" class="addColor" :class="{active:seletedMaterial.index==null}">
                                    <label for="addColor" :style="{backgroundColor:colorSelect}"></label>
                                    <input style="position:absolute;top:0;left:0;right:0;bottom:0;" type="color" id="addColor" name="addColor" value="#ffffff" v-model="colorSelect">
                            </div> -->
                        </div>
                    </div>
                    <div class="avatarEdit" v-if="editingModel.modelType == 'Avatar'" :class="{active:avatarEditActive}">
                        <h4>Guide</h4>
                        <p>You can place the character in the block and let him show the lines</p>
                        <p>Character’s lines<span @click="avatarEditActive=true">Edit</span></p>
                        <textarea :disabled="!avatarEditActive" :class="{active:avatarEditActive}" placeholder="Write down the lines..." v-model="avatarMessage"></textarea>
                        <div class="btnWrap" v-if="avatarEditActive">
                            <button class="cancel" @click="avatarEditActive=false">Cancel</button>
                            <button class="submit" @click="saveMessage">Save</button>
                        </div>
                    </div>
                </div>
            
        </div>
        <div class="sideBar" :class={close:!menuToggle}>
            <div class="sideMenuConent" :class={close:!menuToggle}>
                <div class="title">
                    <!-- <div class="arrow" @click="menuToggle=!menuToggle">
                        <img :class="{arrowrotate:menuToggle}" src="@/assets/img/icon/arrowL.svg" alt="">
                    </div> -->
                    {{selectComponent.name}}
                </div>
                <div class="menu" v-show="menuToggle&&menu">
                    <!-- <el-select v-model="activeTheme" class="filter" placeholder="" :popper-class="'filterDrop'">
                        <el-option v-for="item in filterData" :label="item.name" :value="item.value"></el-option>
                    </el-select> -->
                    <div class="container">
                        <div class="item btn" v-show="menuActive==6">
                            <div class="uploadWrap btn" ref="div">
                                <img src="@/assets/img/uploadModel.svg" alt="">
                                <img src="@/assets/img/uploadModelA.svg" alt="">
                                <!-- <p>{{alertMessage}}</p> -->
                                <label class="btn">
                                    <strong v-if="progress === 0">
                                    {{ label }}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                                    </strong>
                                    <strong v-if="progress > 0 && progress < 100"> {{ progress }}%</strong>
                                    <span v-else>{{ name }}</span>
                                    <input ref="input" type="file" :accept="accept" @change="onFileChange"/>
                                </label>
                                <svg v-if="progress === 100" @click="removeFile" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </div>
                        </div>
                        <div class="item btn" v-for="(item,index) in itemData" :class="{active:selectModel.key==index}" @click="editModel(item,index)" :key="item.editM">
                            <div class="img" :style="{backgroundImage: 'url(' + item.img + ')'}"></div>
                            <p>{{item.name}}</p>
                            <div class="moreContainer" v-if="menuActive==6" :class="{active:exhMoreToggle[index]}" @click="exhMoreToggleMethod(true,index)">
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="message" v-if="exhMoreToggle[index]">
                                    <div class="poly"></div>
                                    <p @mouseenter="hoverIcon=1" @mouseleave="hoverIcon=-1" :style="hoverIcon==1?{color:`#D2D2D2`}:{}" @click="openEdit">Edit</p>
                                    <p @mouseenter="hoverIcon=2" @mouseleave="hoverIcon=-1" :style="hoverIcon==2?{color:`#D2D2D2`}:{}" @click="deletePop">Delete</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="customerEmpty" v-if="this.menuActive==6&&itemData.length==0">
                        <img src="@/assets/img/cusEmpty.png" alt="">
                        <p style="line-height:1.5;">There's nothing here. If you need customized models, please go to the customized page.</p>
                    </div> -->
                </div>
                <button @click="setModel()">Place On</button>
            </div>
            <div class="saveAlert" v-if="saveSceneAlert">
                <div class="container">
                    <img @click="saveSceneAlert=false" src="@/assets/img/icon/cross_black.svg" alt="">
                    <h4>Saved successfully!</h4>
                    <!-- <h4>Saved successfully!  Please decide whether to update your exhibition</h4> -->
                    <!-- <div class="txt">
                        <h5>* This will decide whether to allow your customers to visit the exhibitor or not !</h5> -->
                    <!-- <h5>*You can <span>close / open</span> your exhibition at any time</h5> -->
                    <!-- </div> -->
                    <!-- <button class="submit" @click="goBackToSellerHome('publish')">Open the exhibition and customers can visit</button>
                    <button class="cancel" @click="goBackToSellerHome('draft')">Closed the exhibition and customers cannot visit </button> -->
                    <button class="submit" @click="saveSceneAlert=false">OK</button>
                </div>
            </div>
            <div class="sideMenu">
                <ul>
                    <li :class="{active:menuActive==0}" @click="changeMenu(0)" class="btn">
                        <img src="@/assets/img/icon/component.svg" alt="">
                        <img src="@/assets/img/icon/componentA.svg" alt="">
                        <img src="@/assets/img/modelLabel/component.svg" alt="">
                    </li>
                    <li :class="{active:menuActive==1}" @click="changeMenu(1)" class="btn">
                        <img src="@/assets/img/icon/screen.svg" alt="">
                        <img src="@/assets/img/icon/screenA.svg" alt="">
                        <img src="@/assets/img/modelLabel/monitor.svg" alt="">
                    </li>
                    <li :class="{active:menuActive==2}" @click="changeMenu(2)" class="btn">
                        <img src="@/assets/img/icon/counter.svg" alt="">
                        <img src="@/assets/img/icon/counterA.svg" alt="">
                        <img src="@/assets/img/modelLabel/furniture.svg" alt="">
                    </li>
                    <li :class="{active:menuActive==3}" @click="changeMenu(3)" class="btn">
                        <img src="@/assets/img/icon/character.svg" alt="">
                        <img src="@/assets/img/icon/characterA.svg" alt="">
                        <img src="@/assets/img/modelLabel/character.svg" alt="">
                    </li>
                    <li :class="{active:menuActive==4}" @click="changeMenu(4)" class="btn">
                        <img src="@/assets/img/icon/board.svg" alt="">
                        <img src="@/assets/img/icon/boardA.svg" alt="">
                        <img src="@/assets/img/modelLabel/board.svg" alt="">
                    </li>
                    <li :class="{active:menuActive==5}" @click="changeMenu(5)" class="btn">
                        <img src="@/assets/img/icon/decoration.svg" alt="">
                        <img src="@/assets/img/icon/decorationA.svg" alt="">
                        <img src="@/assets/img/modelLabel/decoration.svg" alt="">
                    </li>
                     <li :class="{active:menuActive==8}" @click="changeMenu(8)" class="btn">
                        <img src="@/assets/img/icon/customized.svg" alt="">
                        <img src="@/assets/img/icon/customizedA.svg" alt="">
                        <img src="@/assets/img/modelLabel/decoration.svg" alt="">
                    </li>
                    <li :class="{active:menuActive==7}" @click="changeMenu(7)" class="btn">
                        <img src="@/assets/img/icon/template.svg" alt="">
                        <img src="@/assets/img/icon/templateA.svg" alt="">
                        <img src="@/assets/img/modelLabel/template.svg" alt="">
                    </li>
                    <li :class="{active:menuActive==6}" @click="changeMenu(6)" class="btn">
                        <img src="@/assets/img/icon/customized.svg" alt="">
                        <img src="@/assets/img/icon/customizedA.svg" alt="">
                        <img src="@/assets/img/modelLabel/customized.svg" alt="">
                    </li>
                </ul>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Header'
import VendorIntroEdit from '@/components/VendorIntroEdit'
import PopAlert from '@/components/Vendor/PopAlert'
import * as BABYLON from "@babylonjs/core";
import "@babylonjs/loaders";//Load External Assets.
import {uploadScenes,getSellerExhDetail,updateSellerModelImg,HostUrl,updateSellerRoomScene,getSellerModel,updateSellerExhData,updateModelData,uploadSellerModel,uploadModelImg,deleteSellerModel,getSellerShareModel,getUserLicence,inExp} from "@/api"
import * as utils from '@/utils/main';
import LoadingSaving from "@/components/LoadingSaving";
import SellerUpgrade from "@/components/SellerUpgrade";
import LoadingProgress from "@/components/LoadingProgress";
import uploadDefault from "@/assets/img/uploadPreview.svg";
import model3dIcon from "@/assets/img/3d-model-icon.png"
import { mapGetters,mapActions } from "vuex";
import axios from 'axios';
// import TutorialVue from '../../components/Tutorial.vue';
import { TorusKnotBuilder } from '@babylonjs/core';
// import template from '@/assets/template/model/Template-1.json'
export default {
    name:'exhEdit',
    data(){
        return{
            headerType:'edit',
            camera:null,
            engine:null,
            scene:null,
            canvas:null,
            ground:null,
            shadowGenerator: null,
            gizmoManager:null,
            models:[],
            bgcData:[
                {
                    value:'Forest',
                    name:'Forest'
                },
                {
                    value:'Space',
                    name:'Space'
                },
                {
                    value:'Playground',
                    name:'Playground'
                }
            ],
            bgc:'Playground',
            activeTheme:'All Theme',
            filterData:[
                {
                    value:'alltheme',
                    name:'All Theme'
                },
                {
                    value:'Theme',
                    name:'Theme'
                }
            ],
            // itemData:[
            //     {
            //         img:screen,
            //         name:'screen'
            //     },
            // ],
            menuToggle:false,
            menuActive:null,
            introForm:false,
            youtubeLink:'',
            imgLink:'',
            menu:true,
            editComponent:false,
            //seletedMaterial:{},
            selectModel:{name:'',img:'',key:null},
            saveSceneData:new FormData(),
            popEdit:false,
            saveSceneAlert:false,
            uploadType:'upload',
            utilLayer:null,
            colorIndex:0,
            editModelType:'move',
            componentIndex:1,
            cusIndex:1,
            colorSelect:'#ffffff',
            editingModel:'',
            loading:false,
            hdrSkybox:null,
            hdrSkyboxMaterial:null,
            hdrTexture:null,
            loadAni:false,
            activeTag:[],
            baseURL: process.env.BASE_URL,
            basicMeshNames:[],
            materialsPool:[],
            position:{
                x:0,
                y:0,
                z:0
            },
            rotation:{
                x:0,
                y:0,
                z:0
            },
            scale:{
                x:0,
                y:0,
                z:0
            },
            modelImgPreview:'',
            modelImg:new FormData(),
            outputArray:[],
            websiteLink:'',
            roomIndex:0,
            modelNoHost:'',
            exhIndex:0,
            editInputToggle:TorusKnotBuilder,
            upgradeToggle:false,
            neededAssets: 99,
            loadedAssets: 0,
            loading:false,
            cameraRadius:30,
            hl:null,
            progress: 0,
            file: null,
            name: '',
            label:'Drag and Drop',
            editModelPop:false,
            editingModelName:'',
            editingModelShare: '',
            editingModelId:'',
            modelImgUploadPreview:'',
            prepareUploadModel:new FormData(),
            exhMoreToggle:[],
            hoverIcon: -1,
            popalert:false,
            popAlertType:'',
            avatarEditActive:false,
            keyDown:{
                c:false,
                command:false
            },
            cloneFire:false,
            avatarMessage:'',
/////////////////////////////////////////////////////////////////////////////////////
            uploadImgPop:false,
            licenceData:[]
/////////////////////////////////////////////////////////////////////////////////////
        }
    },
    props:{
        accept: {
            type: String,
            default: '.glb' // image/png, image/jpeg, application/pdf, application/vnd.ms-excel
        }
    },
    computed:{
        ...mapGetters(["editingExh","exhData","sellerModel","vendor","sellershareModel"]),
        seletedMaterial(){
            if(this.colorIndex==null){
                return {img:null,index:null,color:this.colorSelect}
            }else{
                return {img:this.colorData[this.colorIndex],index:this.colorIndex}
            }
        },
        editingSceneId(){
            if(this.editingExh.rooms)
                return this.editingExh.rooms[this.roomIndex]._id
            else   
                return ''
        },  
        itemData(){
            if(this.menuActive==0){
                return [
                    {
                        img:'/static/models/component/thumbnail/Geo-2-1.jpg',
                        name:'Geo-2-1',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-2-2.jpg',
                        name:'Geo-2-2',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-2-3.jpg',
                        name:'Geo-2-3',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-2-4.jpg',
                        name:'Geo-2-4',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-2-5.jpg',
                        name:'Geo-2-5',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-2-6.jpg',
                        name:'Geo-2-6',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-4-1.jpg',
                        name:'Geo-4-1',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-4-2.jpg',
                        name:'Geo-4-2',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-4-3.jpg',
                        name:'Geo-4-3',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-4-4.jpg',
                        name:'Geo-4-4',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-4-5.jpg',
                        name:'Geo-4-5',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-4-6.jpg',
                        name:'Geo-4-6',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-5-1.jpg',
                        name:'Geo-5-1',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-5-2.jpg',
                        name:'Geo-5-2',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-5-3.jpg',
                        name:'Geo-5-3',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-5-4.jpg',
                        name:'Geo-5-4',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-5-5.jpg',
                        name:'Geo-5-5',
                        modelType:'Component'
                    },
                    {
                        img:'/static/models/component/thumbnail/Geo-5-6.jpg',
                        name:'Geo-5-6',
                        modelType:'Component'
                    },
                ]
            }else if(this.menuActive==1){
                return [
                    {
                        img:'/static/models/monitor/thumbnail/Monitor-1.jpg',
                        name:'Monitor-1',
                        url:'static/models/monitor/model/',
                        fileName:'monitor-1.glb',
                        modelType:'Monitor'
                    },
                    {
                        img:'/static/models/monitor/thumbnail/Monitor-2.jpg',
                        name:'Monitor-2',
                        url:'static/models/monitor/model/',
                        fileName:'monitor-2.glb',
                        modelType:'Monitor'
                    },
                    {
                        img:'/static/models/monitor/thumbnail/Monitor-3.jpg',
                        name:'Monitor-3',
                        url:'static/models/monitor/model/',
                        fileName:'monitor-3.glb',
                        modelType:'Monitor'
                    },
                    {
                        img:'/static/models/monitor/thumbnail/Monitor-4.jpg',
                        name:'Monitor-4',
                        url:'static/models/monitor/model/',
                        fileName:'monitor-4.glb',
                        modelType:'Monitor'
                    },
                    {
                        img:'/static/models/monitor/thumbnail/Monitor-5.jpg',
                        name:'Monitor-5',
                        url:'static/models/monitor/model/',
                        fileName:'monitor-5.glb',
                        modelType:'Monitor'
                    },
                    {
                        img:'/static/models/monitor/thumbnail/Monitor-6.jpg',
                        name:'Monitor-6',
                        url:'static/models/monitor/model/',
                        fileName:'monitor-6.glb',
                        modelType:'Monitor'
                    },
                    {
                        img:'/static/models/monitor/thumbnail/Monitor-7.jpg',
                        name:'Monitor-7',
                        url:'static/models/monitor/model/',
                        fileName:'monitor-7.glb',
                        modelType:'Monitor'
                    },
                ]
            }else if(this.menuActive==2){
                return [
                    {
                        img:'/static/models/counter/thumbnail/furniture-1.jpg',
                        name:'Furniturer-1',
                        url:'static/models/counter/model/',
                        fileName:'furniture-1.glb',
                        modelType:'Furniture'
                    },
                    {
                        img:'/static/models/counter/thumbnail/furniture-2.jpg',
                        name:'Furniturer-2',
                        url:'static/models/counter/model/',
                        fileName:'furniture-2.glb',
                        modelType:'Furniture'
                    },
                    {
                        img:'/static/models/counter/thumbnail/furniture-3.jpg',
                        name:'Furniturer-3',
                        url:'static/models/counter/model/',
                        fileName:'furniture-3.glb',
                        modelType:'Furniture'
                    },
                    {
                        img:'/static/models/counter/thumbnail/furniture-4.jpg',
                        name:'Furniturer-4',
                        url:'static/models/counter/model/',
                        fileName:'furniture-4.glb',
                        modelType:'Furniture'
                    },
                    {
                        img:'/static/models/counter/thumbnail/furniture-5.jpg',
                        name:'Furniturer-5',
                        url:'static/models/counter/model/',
                        fileName:'furniture-5.glb',
                        modelType:'Furniture'
                    },
                    {
                        img:'/static/models/counter/thumbnail/furniture-6.jpg',
                        name:'Furniturer-6',
                        url:'static/models/counter/model/',
                        fileName:'furniture-6.glb',
                        modelType:'Furniture'
                    },
                    {
                        img:'/static/models/counter/thumbnail/furniture-7.jpg',
                        name:'Furniturer-7',
                        url:'static/models/counter/model/',
                        fileName:'furniture-7.glb',
                        modelType:'Furniture'
                    },
                    {
                        img:'/static/models/counter/thumbnail/furniture-8.jpg',
                        name:'Furniturer-8',
                        url:'static/models/counter/model/',
                        fileName:'furniture-8.glb',
                        modelType:'Furniture'
                    },
                    {
                        img:'/static/models/counter/thumbnail/furniture-9.jpg',
                        name:'Furniturer-9',
                        url:'static/models/counter/model/',
                        fileName:'furniture-9.glb',
                        modelType:'Furniture'
                    },
                    {
                        img:'/static/models/counter/thumbnail/furniture-10.jpg',
                        name:'Furniturer-10',
                        url:'static/models/counter/model/',
                        fileName:'furniture-10.glb',
                        modelType:'Furniture'
                    },
                    {
                        img:'/static/models/counter/thumbnail/furniture-11.jpg',
                        name:'Furniturer-11',
                        url:'static/models/counter/model/',
                        fileName:'furniture-11.glb',
                        modelType:'Furniture'
                    },
                    
                ]
            }else if(this.menuActive==3){
                return [
                    {
                        img:'/static/models/avatar/thumbnail/Avatar-v1.png',
                        name:'Avatar-1',
                        url:'static/models/avatar/model/',
                        fileName:'Avatar-v1.glb',
                        modelType:'Avatar'
                    },
                    
                ]
            }else if(this.menuActive==4){
                return [
                    {
                        img:'/static/models/board/thumbnail/Board-1.jpg',
                        name:'Board-1',
                        url:'static/models/board/model/',
                        fileName:'Board-1.glb',
                        modelType:'Board'
                    },
                    {
                        img:'/static/models/board/thumbnail/Board-2.jpg',
                        name:'Board-2',
                        url:'static/models/board/model/',
                        fileName:'Board-2.glb',
                        modelType:'Board'
                    },
                    {
                        img:'/static/models/board/thumbnail/Board-3.jpg',
                        name:'Board-3',
                        url:'static/models/board/model/',
                        fileName:'Board-3.glb',
                        modelType:'Board'
                    },
                ]
            }else if(this.menuActive==5){
                return [
                    {
                        img:'/static/models/decoration/thumbnail/Deco-1.jpg',
                        name:'Deco-1',
                        url:'static/models/decoration/model/',
                        fileName:'deco-1.glb',
                        modelType:'Decoration'
                    },
                    {
                        img:'/static/models/decoration/thumbnail/Deco-2.jpg',
                        name:'Deco-2',
                        url:'static/models/decoration/model/',
                        fileName:'deco-2.glb',
                        modelType:'Decoration'
                    },
                    {
                        img:'/static/models/decoration/thumbnail/Deco-3.jpg',
                        name:'Deco-3',
                        url:'static/models/decoration/model/',
                        fileName:'deco-3.glb',
                        modelType:'Decoration'
                    },
                    {
                        img:'/static/models/decoration/thumbnail/Deco-4.jpg',
                        name:'Deco-4',
                        url:'static/models/decoration/model/',
                        fileName:'deco-4.glb',
                        modelType:'Decoration'
                    },
                    {
                        img:'/static/models/decoration/thumbnail/Deco-5.jpg',
                        name:'Deco-5',
                        url:'static/models/decoration/model/',
                        fileName:'deco-5.glb',
                        modelType:'Decoration'
                    },
                     {
                        img:'/static/models/decoration/thumbnail/temple.jpg',
                        name:'Deco-6',
                        url:'static/models/decoration/model/',
                        fileName:'deco-6.glb',
                        modelType:'Decoration'
                    },

                ]
            }else if(this.menuActive==6){
                let newArray = []
                if(this.sellerModel){
                    this.sellerModel.forEach((item,index)=>{
                        let param = {
                            img:`${item.imageUrl}`,
                            name:item.name,
                            url:`${item.modelUrl}`,
                            fileName:'',
                            modelType:'Customize'
                        }
                        newArray.push(param)
                    })
                }
                return newArray
            }else if(this.menuActive==7){
                return [
                    {
                        img:'/static/models/template/thumbnail/template-1.jpg',
                        name:'Template-1',
                        url:'static/models/template/model/',
                        fileName:'Template-1.json',
                    },
                ]
            }else if(this.menuActive==8){
                 let share_newArray = []
                if(this.sellershareModel){
                    this.sellershareModel.forEach((item,index)=>{
                        let param = {
                            img:`${item.imageUrl}`,
                            name:`${item.name}`,
                            url:`${item.modelUrl}`,
                            fileName:'',
                            modelType:'Customize'
                        }
                        share_newArray.push(param)
                    })
                }
                return share_newArray
            }
        },
        colorData(){
            let newArray = []
            this.materialsPool.forEach((item)=>{
                newArray.push(`/static/models/material/thumbnail/${item.name}.jpg`)
            })
            
            return newArray
        },

        selectComponent(){
            if(this.menuActive==0){
                return {name:'Component',img:this.selectModel.img}
            }else if(this.menuActive==1){
                return {name:'Monitor',img:this.selectModel.img}
            }else if(this.menuActive==2){
                return {name:'Furniture',img:this.selectModel.img}
            }else if(this.menuActive==3){
                return {name:'Display',img:this.selectModel.img}
            }else if(this.menuActive==4){
                return {name:'Board',img:this.selectModel.img}
            }else if(this.menuActive==5){
                return {name:'Decoration',img:this.selectModel.img}
            }else if(this.menuActive==6){
                return {name:'Customized',img:this.selectModel.img}
            }else if(this.menuActive==7){
                return {name:'Template',img:this.selectModel.img}
            }else if(this.menuActive==8){
                return {name:'SharedModels',img:this.selectModel.img}
            }else{
                return {name:'',img:''}
            }

        },
        uploadModelDefault(){
            if(this.modelImgUploadPreview!=''&&this.modelImgUploadPreview!=undefined){
                return this.modelImgUploadPreview
            }else{
                return uploadDefault
            }
        },
        
    },
    components:{
        Header,VendorIntroEdit,LoadingSaving,LoadingProgress,SellerUpgrade,PopAlert
    },
    destroyed() {
        this.SceneTimer = clearInterval(this.SceneTimer)
    },
    methods: {
        ...mapActions(["setEditingExh","setSellerModel","setSellershareModel"]),
        async init(){
            let vm = this
            this.createScene();
            this.setLight();    
            this.setCamera();
            this.setGizmo();
            this.setEngineRender(this.scene, this.engine);
            this.setSceneClick();
            this.setSaveSceneTimer();
            const timer = window.setInterval(async ()=>{
                if(vm.editingExh.hasOwnProperty('rooms')){
                    window.clearInterval(timer);
                    await vm.getSellerModel();
                    await vm.getSellershareModel();
                    vm.initBasicMeshes();
                }
            },100);

            //console.log(this.licenceData)
            let uid = window.localStorage.getItem('userId')
            // let result = getUserLicence(uid)
            // console.log(result)
            // result.then((data) => {
            //     console.log(data.data);
            //     data.data.forEach((item) => {
            //         this.licenceData.push(item);
            //         console.log(this.licenceData[0]);
            //     });
            // })
            // .catch((error) => {
            //     console.error(error);
            // });
        },
        confirm(type,name){
            if(type=='editAccount'){
                this.deleteCusModel();
            }
        },
        closeAlert(){
            this.popalert = false;
        },
        deletePop(){
            this.popalert=true;
            this.hoverIcon = -1;
            this.popAlertType = 'deleteCudModel';
        },
        initDrop(){
            const { div } = this.$refs
                // Determine if drag and drop functionality is capable in the browser
                this.dragAndDropCapable = this.isDragAndDropCapable()
                if(this.dragAndDropCapable) {
                ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( event => {
                    document.body.addEventListener(event, e => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (e.type === 'dragover'||e.type === 'dragenter') {
                            div.classList.add('dragover')
                        }else{
                            div.classList.remove('dragover')
                        }
                    })
                })
                div.addEventListener('drop', e => {
                    this.onFileChange(e)
                })
            }
        },
        exhMoreToggleMethod(bool,idx){
            let oldVal = this.exhMoreToggle[idx];
            this.exhMoreToggle.forEach((item,idx)=>{
                this.$set(this.exhMoreToggle, idx, false);
            });
            if(bool){ this.$set(this.exhMoreToggle, idx, !oldVal);}

        },
        modelUploadImageChange(e){
            const data = URL.createObjectURL(e.target.files[0]);
            this.modelImgUploadPreview = data
            this.modelImg = new FormData()
            this.modelImg.append('image', e.target.files[0]) //放進上傳的檔案
        },
        cancelUploadModel(){
            this.removeFile()
            this.editingModelName = ''
            this.modelImgUploadPreview = ''
            this.editingModelId = ''
            this.editModelPop = false
            this.editingModelShare = ''
        },
        removeFile () {
            this.progress = 0
            this.file = ''
            this.name = null
        },
        async uploadModelShowImg(){
            let res;
            if(this.modelImgUploadPreview != '' && this.modelImgUploadPreview != undefined){
                res = await uploadModelImg(this.modelImg);
                this.modelImg.delete('image');   
                this.uploadImgUrl = res.data.data.url
            }else{
                this.uploadImgUrl = 'https://expoblocks.s3.ap-northeast-1.amazonaws.com/upload/modelImages/pdsYGprU4.png';
            }
            this.updateModelDataFunction()
        },
        async updateModelDataFunction(){
            if (this.editingModelShare == undefined){
                    this.editingModelShare = "0";
                                }
            let param = {
                name: this.editingModelName,
	            imageUrl: this.uploadImgUrl,
                sharemodel: this.editingModelShare
            }
            let res
            this.loading = true
            console.log(param);
            res = await updateModelData(param,this.editingModelId)
            this.loading = false
            this.getSellerModel()
            this.getSellershareModel()
            this.cancelUploadModel()
            this.editModelPop = false
        },
        onFileChange(e) {
            // e.preventDefault()
            let vm = this
            const files = e.target.files || e.dataTransfer.files
            if (!files.length)
                return
            this.createFile(files[0])
        },
        trackProgress (loaded, total) {
            this.progress = parseInt(((loaded / total) * 100), 10)
        },
        createFile(file) {
            const reader = new FileReader()
            const vm = this
            reader.onprogress = e => {
                if (e.lengthComputable) {
                vm.trackProgress(e.loaded, e.total)
                }
            }
            reader.onloadend = e => {
                const { error } = e.target
                if (error != null) {
                    switch (error.code) {
                        case error.ENCODING_ERR:
                        vm.error = 'Encoding error!'
                        break
                        case error.NOT_FOUND_ERR:
                        vm.error = 'File not found!'
                        break
                        case error.NOT_READABLE_ERR:
                        vm.error = 'File could not be read!'
                        break
                        case error.SECURITY_ERR:
                        vm.error = 'Security issue with file!'
                        break
                        default:
                        vm.error = 'I have no idea what\'s wrong!'
                    }
                }
                vm.trackProgress(e.loaded, e.total)
            }
            reader.onload = e => {
                const { result } = e.target
                vm.file = result
                // this.$emit('load', result)
                vm.name = file.name
                vm.editingModelName = vm.name
                const json = JSON.stringify(result);
                const blob = new Blob([json], {
                    type: 'application/json'
                });
                vm.prepareUploadModel = new FormData()
                vm.prepareUploadModel.append('model', file)
                vm.uploadSellerModelFuction()
            }
            reader.readAsBinaryString(file)
        },
        isDragAndDropCapable () {
            const { div } = this.$refs
            return (('draggable' in div)
                || ('ondragstart' in div && 'ondrop' in div))
                && 'FormData' in window
                && 'FileReader' in window
        },
        async uploadSellerModelFuction(){
            let vm = this
            let res = null
            res = await uploadSellerModel(this.prepareUploadModel)            
            if(res.data.status == 201){
                vm.getSellerModel()
                vm.getSellershareModel()
                vm.editModelPop = true
                vm.modelImgUploadPreview = res.data.data.imageUrl
                vm.editingModelName = res.data.data.name
                vm.editingModelId = res.data.data._id
                vm.editingModelShare = res.data.data.sharemodel
                this.$refs.input.value = ''
            }
        },
        openEdit(){
            this.hoverIcon = -1;
            this.editModelPop=true
            this.editingModelName = this.sellerModel[this.selectModel.key].name
            this.modelImgUploadPreview = this.sellerModel[this.selectModel.key].imageUrl
            this.editingModelId = this.sellerModel[this.selectModel.key]._id
            this.editingModelShare = this.sellerModel[this.selectModel.key].sharemodel
        },
         async deleteCusModel(){
            let res 
            res = await deleteSellerModel(this.sellerModel[this.selectModel.key]._id)
            this.getSellerModel()
            this.getSellershareModel()
        },
        goUploadModel(){
            window.open(location.protocol+'//'+window.location.host+'/vendor/edit');
        },
        setSaveSceneTimer(){
            let vm = this;
            this.SceneTimer = setInterval(() => {
                vm.saveScene(false);
            }, 900000);
        },
        changeCameraRadius(e){
            this.cameraRadius = e;
            this.camera.radius = this.cameraRadius;
        },
        editModelFunction(){
            if(this.editingModel.modelType=='Customize')return
            this.popEdit = true
            if(this.editingModel.color)
                this.colorSelect =this.editingModel.color
            else{
                this.seletMaterial(0,this.editingModel.outputMaterial)
            }
            if(this.editingModel.modelType=='Monitor'||this.editingModel.modelType=='Board'){
                //this.modelImgPreview = HostUrl+this.editingModel.imgMaterial
                this.modelImgPreview = this.editingModel.imgMaterial
                this.youtubeLink = this.editingModel.videoLink
                this.websiteLink = this.editingModel.website
                this.imgLink = this.editingModel.imgMaterial
                if(this.editingModel.imgMaterial!='' && this.editingModel.imgMaterial!= undefined ){
                    this.uploadType = 'preview';
                }else{
                    this.uploadType = 'upload';
                }
                this.modelNoHost = this.editingModel.imgMaterial
            }
        },
        changeModel(){
            let vm = this
            if(this.editingModel.type=='Geo'){
                if(vm.colorIndex==null){
                    let myMaterial = new BABYLON.StandardMaterial("myMaterial", this.scene);
                    myMaterial.diffuseColor = new BABYLON.Color3.FromHexString(this.colorSelect)
                    this.editingModel._children[0].material = myMaterial
                    this.editingModel.color = this.colorSelect
                }else{
                    this.editingModel._children[0].material = this.materialsPool[this.colorIndex]
                    this.editingModel.outputMaterial = this.colorIndex
                    this.editingModel.color = ""
                }   
            }else{
                let thisMesh = this.editingModel._children[0]._children[0]._children
                thisMesh.forEach((item,index)=>{
                    if(item.name.split('-')[1] == 'Color1' || item.name.split('-')[1] == 'color1' || item.name.indexOf('Color1') != -1 || item.name.indexOf('color1') != -1 ){
                        if(vm.colorIndex==null){
                            let myMaterial = new BABYLON.StandardMaterial("myMaterial", this.scene);
                            myMaterial.diffuseColor = new BABYLON.Color3.FromHexString(this.colorSelect)
                            item.material = myMaterial
                            this.editingModel.color = this.colorSelect
                        }else{
                            item.material = this.materialsPool[this.colorIndex]
                            this.editingModel.outputMaterial = this.colorIndex
                            this.editingModel.color = ""
                        }   
                    }
                    if(item.name.split('-')[1]){
                        let uploadLabel = item.name.split('-')[1].toLowerCase()
                        if(uploadLabel.includes('uploadhere')&&vm.modelImgPreview!=''){
                            let mat = new BABYLON.StandardMaterial(`UploadHere-img`, this.scene);
                            mat.emissiveTexture = new BABYLON.Texture(vm.modelImgPreview, this.scene);
                            mat.diffuseTexture = new BABYLON.Texture(vm.modelImgPreview, this.scene);
                            mat.diffuseTexture.vScale = -1;
                            mat.emissiveTexture.vScale = -1;
                            item.material = mat;
                            this.editingModel.imgMaterial = vm.modelNoHost
                        }
                    }
                })
                this.editingModel.website = this.websiteLink
                //this.editingModel.website = this.websiteLink
                //this.editingModel.website = "https://playground.babylonjs.com/";
                this.editingModel.videoLink = this.youtubeLink
                // this.editingModel.website = this.modelImgPreview // 檔案連結link
            }
        },
        async modelImageChange(e){
            this.loadAni = true
            const data = URL.createObjectURL(e.target.files[0]);
            this.modelImg = new FormData()
            this.modelImg.append('image', e.target.files[0]) //放進上傳的檔案
            console.log(this.modelImg)
            let res = await updateSellerModelImg(this.modelImg)
            this.modelImgPreview = HostUrl+res.data.data.url
            this.modelNoHost = res.data.data.url
            this.uploadType = 'preview'
            console.log(this.modelImgPreview)
            console.log(this.modelNoHost)
            // this.modelImgPreview = 'https://blockchain-omekas.dlll.nccu.edu.tw/files/large/4235a10631d86c07ee87dfaa14cfc8ce07c97a4e.jpg'
            this.modelNoHost = this.modelImgPreview
            this.changeModel();
            this.loadAni = false
        },
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        async changeImg(img_url){
            // 解析網址
            const url = new URL(img_url);
            if (url.hostname === 'www.youtube.com' || url.hostname === 'youtube.com') {
                // 是 YouTube 網址
                this.youtubeLink = img_url;
            } else {
                // 不是 YouTube 網址，設定圖片預覽和圖片網址
                this.loadAni = true;
                this.modelImgPreview = img_url;
                this.modelNoHost = img_url;
                this.uploadType = 'preview';
                console.log(this.modelImgPreview);
                console.log(this.modelNoHost);
                this.modelImgPreview = img_url;
                this.modelNoHost = this.modelImgPreview;
                const imgUrlL = img_url.replace("/square/", "/large/");
                this.websiteLink = imgUrlL;
                this.changeModel();
                this.loadAni = false;
            }
        },


        async changeImgurl(){
            // 解析網址
                // if(this.imgLink ===''){
                //     this.uploadType = 'preview';
                // }else{
                //         this.uploadType ='upload'
                //     }
                this.loadAni = true;
                this.modelImgPreview = this.imgLink;
                this.modelNoHost = this.imgLink;
                this.uploadType = 'preview';
                console.log(this.modelImgPreview);
                console.log(this.modelNoHost);
                this.modelImgPreview = this.imgLink;
                this.modelNoHost = this.modelImgPreview;
                //const imgUrlL = this.imgLink.replace("/square/", "/large/");

                //this.websiteLink = imgUrlL;
                this.changeModel();
                this.loadAni = false;
        },

        async chooseImg() {
            this.uploadImgPop = true
        },
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        signalChange(type,value){
            let vm = this
            let changeValue = parseFloat(value).toFixed(2)
            if(this.editingModel!=null){
                switch (type) {
                    case 'positionX':
                        vm.editingModel.position.x = changeValue      
                        break;
                    case 'positionY':
                        vm.editingModel.position.y = changeValue
                        break;
                    case 'positionZ':
                        vm.editingModel.position.z = changeValue
                        break;
                    case 'rotationX':
                        vm.editingModel.rotation.x = changeValue* Math.PI / 180
                        break;
                    case 'rotationY':
                        vm.editingModel.rotation.y = changeValue* Math.PI / 180
                        break;
                    case 'rotationZ':
                        vm.editingModel.rotation.z = changeValue* Math.PI / 180
                        break;
                    case 'scaleX':
                        vm.editingModel.scaling.x = changeValue
                        break;
                    case 'scaleY':
                        vm.editingModel.scaling.y = changeValue
                        break;
                    case 'scaleZ':
                        vm.editingModel.scaling.z = changeValue
                        break;
                    default:
                        break;
                }
            }
        },
        async setPlayground(status){

            // this.hdrTexture = new BABYLON.CubeTexture.CreateFromPrefilteredData(`/static/models/scene/${this.bgc}/${this.bgc}.env`, this.scene);
            // this.hdrSkybox = new BABYLON.Mesh.CreateBox("hdrSkyBox", 1000.0, this.scene);
            // // var shapeMaterial = new BABYLON.StandardMaterial("../static/env/SurroundBoxes.env", this.scene);
            // // var hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("../static/env/SurroundBoxes.env", this.scene);
            // this.hdrSkybox.isPickable = false;+
            // this.hdrSkyboxMaterial = new BABYLON.PBRMaterial("skyBox", this.scene);
            // this.hdrSkyboxMaterial.backFaceCulling = false;
            // this.hdrSkyboxMaterial.reflectionTexture = this.hdrTexture.clone();
            // this.hdrSkyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
            // this.hdrSkyboxMaterial.microSurface = 1.0;
            // this.hdrSkyboxMaterial.disableLighting = false;
            // this.hdrSkybox.material = this.hdrSkyboxMaterial;
            // this.hdrSkybox.infiniteDistance = true;
            if(status=='init'){
                this.bgc = 'Playground'
                //this.bgc = 'Space'
            }
            this.bgc = 'Space'
            let dome = new BABYLON.PhotoDome(
                "hdrSkyBox",
                `/static/models/scene/${this.bgc}/${this.bgc}.jpg`,
                {
                    resolution: 32,
                    size: 1000
                },
                this.scene
            );
            let model = {
                name:`${this.bgc}`,
                url:`static/models/scene/${this.bgc}/`,
                fileName:`${this.bgc}.glb`
            }
            await this.loadPlayground({
                url: modelUrl+model.url,
                fileName : model.fileName,
                pos: new BABYLON.Vector3(0,0,0),
                scene: this.scene,
                name:model.name,
                type:'playground'
            },status);
        },
        deleteModel(){
            let vm  = this
            //let thisMesh = this.scene.getMeshByName(this.editingModel)
            if(this.gizmoManager._attachedMesh!=null){
                let deleteItemName = this.gizmoManager._attachedMesh.name
                this.models.forEach((item,index)=>{
                    if(item.name == deleteItemName)
                        vm.models.splice(index, 1);
                })
                this.gizmoManager.attachableMeshes = this.models
                this.gizmoManager._attachedMesh.dispose()
                this.gizmoManager._attachedMesh = null
                this.gizmoManager.rotationGizmoEnabled = false;
                this.gizmoManager.positionGizmoEnabled = false;
                this.gizmoManager.boundingBoxGizmoEnabled = false;
                this.position.x = this.position.y = this.position.z = this.rotation.x = this.rotation.y = this.rotation.z = this.scale.x = this.scale.y = this.scale.z = 0
                this.editingModel = null
                this.popEdit = false;
            }
        },
        getOutermostParent(mesh){
            if(mesh._parentNode===null)return mesh;
            let parent = mesh;
            while(parent._parentNode!==null){
                parent = parent?._parentNode || null;
            }
            return parent;
        },
        setSceneClick(){
            let vm = this
            this.scene.onPointerDown = (event, pickResult)=>{
                if(!pickResult.hit) return;
                let name = pickResult.pickedMesh.name;
                let parent
                let thisMesh = vm.scene.getMeshByName(name)
                if(this.editModelType=='rotate'){
                    this.gizmoManager.rotationGizmoEnabled = true;
                }else if(this.editModelType=='scale'){
                    this.gizmoManager.scaleGizmoEnabled = true;
                }else if(this.editModelType=='move'){
                    this.gizmoManager.positionGizmoEnabled = true;
                }
                // this.gizmoManager.gizmos.scaleGizmo.xGizmo.isEnabled = true;
                // this.gizmoManager.gizmos.scaleGizmo.yGizmo.isEnabled = true;
                // this.gizmoManager.gizmos.scaleGizmo.zGizmo.isEnabled = true;

                // if(name.indexOf('_')!=-1){
                //     if(name.split('_')[0] == 'Monitor'||name.split('_')[0] == 'Counter'||name.split('_')[0] == 'Display'||name.split('_')[0] == 'Board'||name.split('_')[0] == 'Deco'||name.split('_')[0] == 'info'||name.split('_')[0] == 'Decoration'){
                //         parent = pickResult.pickedMesh._parentNode._parentNode._parentNode
                //     }else if(name.split('_')[1] =='lowpoly'){
                //         parent = pickResult.pickedMesh._parentNode._parentNode
                //         // this.gizmoManager.gizmos.scaleGizmo.xGizmo.isEnabled = false;
                //         // this.gizmoManager.gizmos.scaleGizmo.yGizmo.isEnabled = false;
                //         // this.gizmoManager.gizmos.scaleGizmo.zGizmo.isEnabled = false;
                //     }else{
                //         parent = pickResult.pickedMesh._parentNode 
                //     }
                // }else{
                //     parent = pickResult.pickedMesh._parentNode   
                // }

                parent = this.getOutermostParent(pickResult.pickedMesh);
                if(this.editingModel){
                    vm.setMeshLine(false,this.editingModel)
                }
                this.editingModel = null
                if(parent.name!='playground' && parent.name!='hdrSkyBox'){
                //if(parent.name!='hdrSkyBox'){
                    vm.setInputValue(parent)
                    vm.setMeshLine(true,parent);
                    vm.editingModel = parent;
                    //vm.colorIndex = vm.editingModel.outputMaterial
                    vm.editModelFunction();
                    if(vm.editModelType=="move"){
                        vm.avatarMessage = vm.editingModel.message;
                    }
                    vm.popEdit = true;
                }else{
                    if(vm.gizmoManager._attachedMesh){
                        vm.setMeshLine(false,vm.gizmoManager._attachedMesh)
                        vm.gizmoManager._attachedMesh = null;
                        this.popEdit = false;
                        this.gizmoManager.rotationGizmoEnabled = false;
                        this.gizmoManager.scaleGizmoEnabled = false;
                        this.gizmoManager.positionGizmoEnabled = false;
                    }
                    // if(vm.gizmoManager._attachedMesh){
                    //     vm.setMeshLine(true,vm.gizmoManager._attachedMesh)
                    //     this.editingModel = vm.gizmoManager._attachedMesh
                    // }
                }
            }
        },
        async saveScene(bool){
            this.outputArray = []
            this.models.forEach((item)=>{
                let mesh = {
                    parentName:item.name,
                    position: {x:item.position.x, y: item.position.y, z:item.position.z},
                    scaling: {x:item.scaling.x, y: item.scaling.y, z: item.scaling.z},
                    rotation: {x: item.rotation.x, y: item.rotation.y, z: item.rotation.z},
                    type:item.type,
                    videoLink:item.videoLink,
                    website:item.website,
                    material:"",
                    childName:item.childName,
                    url:item.url,
                    fileName:item.fileName,
                    imgMaterial:item.imgMaterial,
                    outputMaterial:item.outputMaterial,
                    color:item.color,
                    img:item.img,
                    modelType:item.modelType,
                    message:item.message
                }
                this.outputArray.push(mesh)
            })
                
            let newJson = {
                bgc:this.bgc,
                meshData:this.outputArray
            }
            let param = {
                accessories:newJson
            }
            if(bool){
                this.loadAni = true
            }
            let res = await updateSellerRoomScene(param,this.editingExh.rooms[this.roomIndex]._id)

            this.loadAni = false
            this.getEditng()
            if(bool){
                //this.saveSceneAlert = true;
                this.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: 'Saved successfully !',
                    type: 'success',
                    duration: 1500,
                });
            }
        },
        seletMaterial(item,index,type){
            if(type=='color'){
                this.colorIndex = null;
            }else if(type=='asyncModle'){
                this.colorIndex = index;
                this.changeModel();
            }else{
                this.colorIndex = index
            }
            
        },
        getSellerModel(){
            const vm = this;
            return new Promise(async(resolve, reject) => {
                let res = await getSellerModel()
                if(res.data.status==200){
                    vm.setSellerModel(res.data.data);
                    console.log(res.data.data)
                    resolve('finish');
                }
                //let res2 = await getSellerShareModel()
                //    console.log(res2);
            })
        },
        getSellershareModel(){
            const vm = this;
            return new Promise(async(resolve, reject) => {
                let res = await getSellerShareModel()
                if(res.data.status==200){
                    vm.setSellershareModel(res.data.data);
                    console.log(res.data.data)
                    resolve('share_finish');
                }
                //let res2 = await getSellerShareModel()
                //    console.log(res2);
            })
        },
        changeMenu(num){
            if(this.menuActive==num){
                this.menuToggle = !this.menuToggle;
            }
            else{
                this.menuToggle = true;
            }
            if(!this.menuToggle)
                this.menuActive = null;
            else
                this.menuActive = num;
        },
        editModel(item,index){
            this.selectModel = item
            this.selectModel.key = index
            this.selectModel.img = item.img
        },
        cancelSetting(){
            this.youtubeLink = ''
            this.editComponent = false
            this.uploadType='upload'
            this.menu = true
            this.seletMaterial(0,0);
            // let container = this.$el.querySelector("#editComponent");
            // let container1 = this.$el.querySelector("#editComponent1");
            // container1.scrollTop = container.scrollTop = 0;
        },
        async setModel(){
            let model = this.selectModel
            let vm = this
            model.color = this.colorSelect
            let modelHost
            this.loadAni = true
            if(this.menuActive==7){
                this.loading = false
                this.loadedAssets = 0
                axios.get(`/${this.selectModel.url}${this.selectModel.name}.json`).then((res) => { 
                    let jsonData = res.data
                    vm.loadingScene(jsonData.meshData,'template')
                })
                return 
            }
            vm.cancelSetting();
            if(this.menuActive==0){
                
                this.importBasicMesh(model.key,'addNew',model)
                return
            }
            if(this.menuActive!=6 && this.menuActive!=8){
                modelHost = modelUrl
            }else{
                //modelHost = HostUrl
                modelHost = "";
            }
            await this.importModel({
                url: model.url,
                fileName : model.fileName,
                pos: new BABYLON.Vector3(0,0.5,0),
                scene: this.scene,
                texture:this.seletedMaterial,
                name:model.name,
                host:modelHost,
                img:this.selectModel.img,
                modelType:this.selectModel.modelType
            },'addNew');     
        },
        createScene(){
            let vm = this;
            let head = document.getElementById("head");
            this.canvas = document.getElementById("renderCanvas");
            this.engine = new BABYLON.Engine(this.canvas, true, { stencil: true });
            BABYLON.Database.IDBStorageEnabled = false;
            this.engine.loadingScreen = new utils.CustomLoadingScreen();
            //this.engine.setSize(window.innerWidth, window.innerHeight-head.clientHeight);
            this.engine.setSize(window.innerWidth, window.innerHeight);
            //this.engine.setSize();
            this.scene = new BABYLON.Scene(this.engine);
            this.scene.clearColor = new BABYLON.Color3(0.9, 0.9, 0.9);
            // this.scene.clearColor = new BABYLON.Color3.FromHexString('#f8edeb');
            window.scene = this.scene
            let helper = this.scene.createDefaultEnvironment({
                createSkybox: false,
                cameraExposure: 1,
                cameraContrast: 1.5,
            });
            helper.ground.dispose();

            this.hl = new BABYLON.HighlightLayer("hl1", this.scene);

        },
        setGizmo(){
            let vm = this
            this.gizmoManager = new BABYLON.GizmoManager(this.scene);
            this.gizmoManager.updateGizmoPositionToMatchAttachedMesh = true;

            this.gizmoManager.boundingBoxGizmoEnabled=true;
            this.gizmoManager.positionGizmoEnabled = true;
            this.gizmoManager.rotationGizmoEnabled = true;

            this.gizmoManager.scaleGizmoEnabled = true;
            this.utilLayer = this.gizmoManager.utilityLayer;
            this.gizmoManager.gizmos.scaleGizmo.uniformScaleGizmo.updateGizmoRotationToMatchAttachedMesh = true;
            this.gizmoManager.updateGizmoPositionToMatchAttachedMesh = true;

            this.gizmoManager.gizmos.scaleGizmo.xGizmo.scaleRatio = 2;
            this.gizmoManager.gizmos.scaleGizmo.yGizmo.scaleRatio = 2;
            this.gizmoManager.gizmos.scaleGizmo.zGizmo.scaleRatio = 2;
            this.gizmoManager.gizmos.scaleGizmo.uniformScaleGizmo.scaleRatio = 2;

            this.changeGizmo('move')
            this.gizmoManager.attachableMeshes = this.models;
            
            this.gizmoManager.gizmos.positionGizmo.xGizmo.dragBehavior.onDragObservable.add((event) => {
                vm.setInputValue(vm.gizmoManager._attachedMesh)
            })
            this.gizmoManager.gizmos.positionGizmo.yGizmo.dragBehavior.onDragObservable.add((event) => {
                vm.setInputValue(vm.gizmoManager._attachedMesh)
            })
            this.gizmoManager.gizmos.positionGizmo.zGizmo.dragBehavior.onDragObservable.add((event) => {
                vm.setInputValue(vm.gizmoManager._attachedMesh)
            })
            this.gizmoManager.gizmos.scaleGizmo.uniformScaleGizmo.dragBehavior.onDragObservable.add((event) => {
                vm.setInputValue(vm.gizmoManager._attachedMesh)
            })
            this.gizmoManager.gizmos.scaleGizmo.yGizmo.dragBehavior.onDragObservable.add((event) => {
                if(vm.gizmoManager._attachedMesh.modelType=="Customize"){
                    vm.gizmoManager._attachedMesh.scaling.x = vm.gizmoManager._attachedMesh.scaling.z = vm.gizmoManager._attachedMesh.scaling.y
                }
                vm.setInputValue(vm.gizmoManager._attachedMesh)
            })
            this.gizmoManager.gizmos.scaleGizmo.xGizmo.dragBehavior.onDragObservable.add((event) => {
                if(vm.gizmoManager._attachedMesh.modelType=="Customize"){
                    vm.gizmoManager._attachedMesh.scaling.y = vm.gizmoManager._attachedMesh.scaling.z = vm.gizmoManager._attachedMesh.scaling.x
                }
                vm.setInputValue(vm.gizmoManager._attachedMesh)
            })
            this.gizmoManager.gizmos.scaleGizmo.zGizmo.dragBehavior.onDragObservable.add((event) => {
                if(vm.gizmoManager._attachedMesh.modelType=="Customize"){
                    vm.gizmoManager._attachedMesh.scaling.y = vm.gizmoManager._attachedMesh.scaling.x = vm.gizmoManager._attachedMesh.scaling.z
                }
                vm.setInputValue(vm.gizmoManager._attachedMesh)
            })
            this.gizmoManager.gizmos.rotationGizmo.yGizmo.dragBehavior.onDragObservable.add((event) => {
                vm.setInputValue(vm.gizmoManager._attachedMesh)
            })
            this.gizmoManager.gizmos.rotationGizmo.xGizmo.dragBehavior.onDragObservable.add((event) => {
                vm.setInputValue(vm.gizmoManager._attachedMesh)
            })
            this.gizmoManager.gizmos.rotationGizmo.zGizmo.dragBehavior.onDragObservable.add((event) => {
                vm.setInputValue(vm.gizmoManager._attachedMesh)
            })

        },
        setInputValue(mesh){
            let vm  = this
            vm.position.x = parseFloat(mesh.position.x).toFixed(2)
            vm.position.y = parseFloat(mesh.position.y).toFixed(2)
            vm.position.z = parseFloat(mesh.position.z).toFixed(2)
            vm.rotation.x = parseFloat((mesh.rotation.x*180)/Math.PI).toFixed(2)
            vm.rotation.y = parseFloat((mesh.rotation.y*180)/Math.PI).toFixed(2)
            vm.rotation.z = parseFloat((mesh.rotation.z*180)/Math.PI).toFixed(2)
            vm.scale.x = parseFloat(mesh.scaling.x).toFixed(2)
            vm.scale.y = parseFloat(mesh.scaling.y).toFixed(2)
            vm.scale.z = parseFloat(mesh.scaling.z).toFixed(2)
        },
        changeGizmo(type){
            window.scene = this.scene;
            let utilLayer = this.gizmoManager.utilityLayer;
            this.gizmoManager.scaleGizmoEnabled = false;
            this.gizmoManager.rotationGizmoEnabled = false;
            this.gizmoManager.positionGizmoEnabled = false;
            this.gizmoManager.boundingBoxGizmoEnabled = false;
            this.editModelType = type
            if(type=='rotate'){
                this.gizmoManager.rotationGizmoEnabled = true;
            }else if(type=='scale'){
                this.gizmoManager.scaleGizmoEnabled = true;
            }else if(type=='move'){
                this.gizmoManager.positionGizmoEnabled = true;
                //this.gizmoManager.gizmos.positionGizmo.xGizmo = new BABYLON.AxisDragGizmo(new BABYLON.Vector3(1,0,0), BABYLON.Color3.FromHexString("#00b894"), this.utilLayer);
            }
        },
        async importModel(data,type){
            //console.log(data)
            let _container,loader;
            let vm = this
            let materialId = 1
            let mixUrl = data.host+data.url
            const dateTime = Date.now();
            const timestamp = Math.floor(dateTime / 1000);
            BABYLON.SceneLoader.ImportMesh("",mixUrl, data.fileName, this.scene, (newMeshes)=>{
                let container
                if(type=='addNew'){
                    container = new BABYLON.Mesh("con_"+timestamp,this.scene)
                    console.log('test2')
                }else{
                    container = new BABYLON.Mesh(data.parentName,this.scene)
                    this.loadedAssets++;
                    if(this.loadedAssets == this.neededAssets){
                        this.loading = false
                        this.loadAni = false
                        this.loadedAssets = 0
                    }
                }
            //console.log('test')
                newMeshes.forEach((item,index)=>{
                    vm.shadowGenerator.addShadowCaster(item);
                    vm.shadowGenerator.getShadowMap().renderList.push(item);
                    if(type=='addNew'){
                        if(vm.menuActive != 6 && vm.menuActive != 8){
                            container.type = 'notGeo'
                            if(item.name.split('-')[1] == 'Color1' || item.name.split('-')[1] == 'color1' || item.name.indexOf('Color1') != -1 || item.name.indexOf('color1') != -1 ){
                                if(vm.colorIndex==null){
                                    let myMaterial = new BABYLON.StandardMaterial("myMaterial", this.scene);
                                    myMaterial.diffuseColor = new BABYLON.Color3.FromHexString(data.texture.color)
                                    item.material = myMaterial
                                    container.color = data.texture.color
                                    container.outputMaterial = ""
                                }else{
                                    item.material = vm.materialsPool[vm.colorIndex]
                                    container.outputMaterial = vm.colorIndex
                                    container.color = ""
                                }
                        
                            }
                            // if(vm.modelImgPreview != ''){
                            //     if(item.name.split('-')[1]){
                            //         let uploadLabel = item.name.split('-')[1].toLowerCase()
                            //         if(uploadLabel.includes('uploadhere')){
                            //             let mat = new BABYLON.StandardMaterial(`${container.name}-UploadHere-${materialId}`, this.scene);
                            //             mat.emissiveTexture = new BABYLON.Texture(vm.modelImgPreview, this.scene);
                            //             mat.diffuseTexture = new BABYLON.Texture(vm.modelImgPreview, this.scene);
                            //             mat.diffuseTexture.vScale = -1;
                            //             mat.emissiveTexture.vScale = -1;
                            //             item.material = mat;
                            //             materialId++
                            //             container.imgMaterial = vm.modelNoHost
                            //         }
                            //     }
                            // }
                        }else{                
                            container.type = 'cus'
                        }
                    }else{
                        if(data.type=='notGeo'){
                            container.type = 'notGeo'
                            if(item.name.split('-')[1] == 'Color1' || item.name.split('-')[1] == 'color1' || item.name.indexOf('Color1') != -1 || item.name.indexOf('color1') != -1 ){
                                if(data.color){
                                    let myMaterial = new BABYLON.StandardMaterial("myMaterial", this.scene);
                                    myMaterial.diffuseColor = new BABYLON.Color3.FromHexString(data.color)
                                    item.material = myMaterial
                                    container.color = data.color
                                    container.outputMaterial = ""
                                }else{
                                    item.material = vm.materialsPool[data.outputMaterial]
                                    container.outputMaterial = data.outputMaterial
                                    container.color=""
                                }
                            }
                            if(data.imgMaterial){
                                if(item.name.split('-')[1]){
                                    let uploadLabel = item.name.split('-')[1].toLowerCase()
                                    if(uploadLabel.includes('uploadhere')){
                                        let mat = new BABYLON.StandardMaterial(`${container.name}-UploadHere-${materialId}`, this.scene);
                                        mat.emissiveTexture = new BABYLON.Texture(HostUrl+data.imgMaterial, this.scene);
                                        mat.diffuseTexture = new BABYLON.Texture(HostUrl+data.imgMaterial, this.scene);
                                        mat.diffuseTexture.vScale = -1;
                                        mat.emissiveTexture.vScale = -1;
                                        item.material = mat;
                                        materialId++
                                        container.imgMaterial = data.imgMaterial
                                    }
                                }
                            }
                        }else{
                            container.type = 'cus'
                        }
                    }
                    
                })
                if(type=='addNew'){
                    container.addChild(newMeshes[0])
                    container.position.y = 0
                    container.videoLink = this.youtubeLink
                    container.website = this.websiteLink
                    this.gizmoManager.attachedMesh = container;
                    this.gizmoManager._attachedMesh = container;
                    this.avatarMessage = "";
                    this.setInputValue(container)
                    if(this.editingModel)
                        this.setMeshLine(false,this.editingModel)
                    this.setMeshLine(true,container)
                    this.editingModel = container
                    this.loadAni = false
                    if(vm.menuActive != 6){this.popEdit = true;}
                }else{  
                    container.addChild(newMeshes[0])
                    container.position.y = data.position.y
                    container.position.x = data.position.x
                    container.position.z = data.position.z
                    container.rotation.y = data.rotation.y
                    container.rotation.x = data.rotation.x
                    container.rotation.z = data.rotation.z
                    container.scaling.y = data.scaling.y
                    container.scaling.x = data.scaling.x
                    container.scaling.z = data.scaling.z
                    container.videoLink = data.videoLink
                    container.website = data.website;
                    container.message = data.message;
                }
                container.modelType = data.modelType
                container.img = data.img
                container.childName = ''
                container.url = data.url
                container.fileName = data.fileName
                vm.models.push(container)
                if(this.editModelType=='rotate'){
                    this.gizmoManager.rotationGizmoEnabled = true;
                }else if(this.editModelType=='scale'){
                    this.gizmoManager.scaleGizmoEnabled = true;
                }else if(this.editModelType=='move'){
                    this.gizmoManager.positionGizmoEnabled = true;
                }
                this.loadAni = false;
            })
        console.log('done');
        },
        setMeshLine(bool,mesh){
                if(mesh!=null&&mesh!=undefined){
                    if(mesh.type){
                        if(mesh.type=='Geo'){
                            mesh._children.forEach((item)=>{
                                // item.outlineWidth = 1.2;
                                // item.outlineColor = BABYLON.Color3.Red();
                                // item.renderOutline = bool;
                                if(bool){
                                    this.hl.addMesh(item,new BABYLON.Color3(0.97, 0.55, 0.15));
                                }else{
                                    this.hl.removeMesh(item);
                                }
                            })
                        }else if(mesh.type!='Geo'&&mesh.type!='cus'){
                            if(mesh._children.length!=0){
                                if(mesh._children[0]._children[0]._children !=null && mesh._children[0]._children[0]._children !=undefined){
                                    mesh._children[0]._children[0]._children.forEach((item)=>{
                                        // item.outlineWidth = 1.2;
                                        // item.outlineColor = BABYLON.Color3.Red();
                                        // item.renderOutline = bool;
                                        if(bool){
                                            this.hl.addMesh(item,new BABYLON.Color3(0.97, 0.55, 0.15));
                                        }else{
                                            this.hl.removeMesh(item);
                                        }
                                    })
                                }
                            }
                        }
                    }
                }
            
        },
        importBasicMesh(data,type,modelData){ ////////////////
            let vm = this
            let selectName
            let thisMesh
            let cloneMesh 
            let container
            const dateTime = Date.now();
            const timestamp = Math.floor(dateTime / 1000);
            if(type=='addNew'){
                selectName = vm.basicMeshNames[data];
                container = new BABYLON.Mesh("Geo_"+timestamp,this.scene)
            }else{
                selectName = data.childName
                container = new BABYLON.Mesh(data.parentName,this.scene)
                this.loadedAssets++;
                if(this.loadedAssets == this.neededAssets){
                    this.loading = false
                    this.loadAni = false
                    this.loadedAssets = 0
                }
            }
            thisMesh = vm.scene.getMeshByName(selectName);
            cloneMesh = thisMesh.clone();
            container.type="Geo";
            cloneMesh.setEnabled(true);
            cloneMesh.name = selectName;
            cloneMesh.id = selectName;
            cloneMesh.receiveShadows = true;
            container.childName = selectName;
            this.shadowGenerator.addShadowCaster(cloneMesh);
            this.shadowGenerator.getShadowMap().renderList.push(cloneMesh);
            if(type=='addNew'){
                if(vm.colorIndex==null){
                    let myMaterial = new BABYLON.StandardMaterial("myMaterial", this.scene);
                    myMaterial.diffuseColor = new BABYLON.Color3.FromHexString(modelData.color)
                    cloneMesh.material = myMaterial
                    container.color = modelData.color
                }else{
                    cloneMesh.material = this.materialsPool[this.colorIndex]
                    container.outputMaterial = this.colorIndex
                    container.color = ""
                }   
                container.addChild(cloneMesh)
                container.position.y = 0
                container.img = modelData.img
                container.editModelType = 'Component'
                this.gizmoManager.attachedMesh = container
                this.gizmoManager._attachedMesh = container
                this.setInputValue(container)
                if(this.editingModel)
                    this.setMeshLine(false,this.editingModel)
                this.setMeshLine(true,container)
                this.editingModel = container
                this.loadAni = false
                this.popEdit = true;
            }else{
                if(data.color){
                    let myMaterial = new BABYLON.StandardMaterial("myMaterial", this.scene);
                    myMaterial.diffuseColor = new BABYLON.Color3.FromHexString(data.color)
                    cloneMesh.material = myMaterial
                    container.color = data.color
                    container.outputMaterial = ""  
                }else{
                    cloneMesh.material = this.materialsPool[data.outputMaterial]
                    container.outputMaterial = data.outputMaterial
                    container.color = ""
                }
                container.addChild(cloneMesh)
                container.position.y = data.position.y
                container.position.x = data.position.x
                container.position.z = data.position.z
                container.rotation.y = data.rotation.y
                container.rotation.x = data.rotation.x
                container.rotation.z = data.rotation.z
                container.scaling.y = data.scaling.y
                container.scaling.x = data.scaling.x
                container.scaling.z = data.scaling.z
                container.img = data.img
                container.editModelType = data.editModelType
            }
            container.videoLink = ''
            container.website = ''
            container.url = ''
            container.filename = ''
            container.imgMaterial = ''
            this.models.push(container) ////////////////////
            if(this.editModelType=='rotate'){
                    this.gizmoManager.rotationGizmoEnabled = true;
                }else if(this.editModelType=='scale'){
                    this.gizmoManager.scaleGizmoEnabled = true;
                }else if(this.editModelType=='move'){
                    this.gizmoManager.positionGizmoEnabled = true;
            }
            this.componentIndex++
        },
        initBasicMeshes(){
            let data = {
                url:modelUrl+'static/models/component/model/',
                fileName:'Geo.glb'
            }
            let vm = this
            this.loading = true
            this.loadedAssets = 0
            BABYLON.SceneLoader.ImportMesh("",data.url, data.fileName, this.scene, (container)=>{
                //let meshes = container.meshes;
                container.forEach((item,index)=>{
                    if(index!=0){
                        item.setEnabled(false)
                        vm.basicMeshNames.push(item.name)
                    }
                })
                 this.initBasicMaterial()
            })
        },
        async initBasicMaterial(){
            let data = {
                url:modelUrl+'static/models/material/',
                fileName:'Material.glb'
            }
            let vm = this
            await BABYLON.SceneLoader.AppendAsync(data.url, data.fileName, this.scene).then(async (scene) =>{
                const materialList = ["Concrete", "Floor", "Grass-1", "Metal", "Plastic-1", "Wood", "Marble", "Leather-1"];
                let setting = function (){
                    return new Promise((resolve, reject) => {
                        scene.materials.forEach((item, index)=>{
                            if(item.name.includes("Concrete")||item.name.includes("Floor")||item.name=='Grass-1'||item.name.includes("Metal")||item.name=='Plastic-1'||item.name.includes('Wood')||item.name.includes('Marble')||item.name=='Leather-1'){
                                vm.materialsPool.push(item);
                                let thisMesh = scene.getMeshByName(item.name);
                                thisMesh.setEnabled(false)
                                if(index == scene.materials.length - 1){
                                    resolve('finish');
                                }
                            }
                        })
                    })
                }
                await setting();
                if(vm.editingExh.rooms[vm.roomIndex].sceneUrl){
                    let sceneUrl = vm.editingExh.rooms[vm.roomIndex].sceneUrl
                    axios.get(sceneUrl, {
                        transformRequest: (data, headers) => {
                            delete headers.common['Authorization'];
                        }
                    }).then((res) => { 
                        let jsonData = res.data
                        vm.bgc = jsonData.bgc
                        
                        vm.loadingScene(jsonData.meshData)
                        if(jsonData.meshData.length==0){
                            vm.setPlayground('onlyPlayground')
                        }else{
                            vm.setPlayground()
                        }
                    })
                }else{
                    vm.setPlayground('init')
                }
            })
        },
        loadPlayground(data,status){
            this.loadAni = true
            let vm = this
            let loader = BABYLON.SceneLoader.ImportMesh('',data.url,data.fileName,this.scene,(newMeshes)=>{
                let meshes = new BABYLON.Mesh("playground", this.scene);
                newMeshes.forEach((item)=>{
                    meshes.addChild(item)
                    vm.shadowGenerator.addShadowCaster(item);
                    vm.shadowGenerator.getShadowMap().renderList.push(item);
                })
                this.loadAni = false
                if(status!=undefined){
                    this.loadedAssets = this.neededAssets
                    let timeout = window.setTimeout( () => {
                        vm.loading = false
                        vm.loadedAssets = 0
                        window.clearTimeout(timeout);
                    }, 1000);
                }
                // this.setLight();
            })
        },
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        async loadingScene(meshData,process){ 
            console.log('......')
            let imgList = [];
            let uid = window.localStorage.getItem('userId')
            console.log(uid)
            // await inExp(uid).then((data) => {
            //     // console.log(data.data);
            //     imgList = Object.values(data.data) // 取出所有網址
            // })
            // console.log(imgList)
            let vm = this
            if(meshData.length == 0){
                this.loadedAssets = 99;
                return
            }
            this.neededAssets = meshData.length
            meshData.forEach(async (item,index)=>{
                // console.log(item.imgMaterial)
                // if (imgList.includes(item.imgMaterial)) {
                //     // 如果包含指定的網址，執行對應的程式碼
                //     console.log("Image in Exp");
                // } else {
                //     // 如果不包含指定的網址，將imgMaterial設為空字串
                //     item.imgMaterial = '';
                //     console.log("Image not in Exp");
                // }
                if(item.type=='Geo'){
                    await vm.importBasicMesh(item,'load')
                }else{
                    if(item.type=='notGeo'){
                        item.host = modelUrl
                    }else{
                        item.host = HostUrl ///////印出item
                    }
                    await vm.importModel(item,'load') 
                }
            })
        },
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////        
        changeRoom(idx){
            this.roomIndex = idx
            this.scene.dispose()
            this.engine.dispose()
            this.materialsPool.length = 0
            this.models.length = 0 
            this.gizmoManager = null
            this.init()
        },
        setEngineRender(scene, engine){
            let vm = this
            this.scene.registerBeforeRender(()=>{
                vm.camerasBorderFunction()
                vm.cameraRadius = vm.camera.radius;
            })
            engine.runRenderLoop(()=>{
                vm.scene.render();
                // engine.resize(window.innerWidth, window.innerHeight);
                vm.engine.resize();
                this.currentFps = engine.getFps().toFixed();
            });
        },
        setCamera(){
            let depth = 10;
            this.camera = new BABYLON.ArcRotateCamera('arcCamera', 0, 0,  25, new BABYLON.Vector3(depth,depth,depth), this.scene);
            this.camera.attachControl(this.canvas, true);
            //相機縮放限制
            this.camera.lowerRadiusLimit = 6;
            this.camera.upperRadiusLimit = 60;
            //相機瞄準(0,0,0)
            this.camera.setTarget(BABYLON.Vector3.Zero());
            //相機向上旋轉限制
            this.camera.lowerBetaLimit = 0.3;
            //相機向下旋轉限制
            this.camera.upperBetaLimit = 1.5; 
            this.camera.radius = this.cameraRadius;
            
            this.camera.beta = Math.PI/3;
            this.camera.alpha = Math.PI/2;
            this.camera.minZ = 0.1;
            this.camera.wheelDeltaPercentage = 0.005;
            this.camera.panningSensibility = 75;
        },
        setLight(){

            let hLight = new BABYLON.HemisphericLight('hLight', new BABYLON.Vector3(1,1,1), this.scene);
            hLight.intensity = 1;
            let light = new BABYLON.DirectionalLight("light", new BABYLON.Vector3(0, -2, 0), this.scene);
            light.intensity = 2;
            light.position = new BABYLON.Vector3(0, 50, 0);

            this.shadowGenerator = new BABYLON.ShadowGenerator(1024, light);
            this.shadowGenerator.useBlurExponentialShadowMap = false;
            this.shadowGenerator.useKernelBlur = true;
            this.shadowGenerator.blurKernel = 64;
            this.shadowGenerator.blurScale = 1;
            this.shadowGenerator.usePoissonSampling = false;
            this.shadowGenerator.getShadowMap().refreshRate = 5;

        },
        camerasBorderFunction(){
            if(this.camera.beta < 0.1){
                this.camera.beta = 1;
            }else if(this.camera.beta > (Math.PI /2 *0.9)){
                this.camera.beta = (Math.PI /2 *0.9)
            }
            if(this.camera.radius > 150) this.camera.radius = 150;
        },
        closeIntro(val){
            this.introForm = val;
        },
        openIntoEdit(val){
            this.introForm = val;
        },
        async getEditng(){
            let res = await getSellerExhDetail(this.exhData[parseInt(this.$route.query.exh)]._id)
            this.exhIndex = this.$route.query.exh
            this.activeTag.length = 0 
            this.setEditingExh(res.data.data)
            res.data.data.tags.forEach((item)=>{
                this.activeTag.push(item)
            })
        } ,
        async goBackToSellerHome(type){
            this.saveSceneAlert=false
            let res = await this.changeStatus(type)
            window.location.href = location.protocol+'//'+window.location.host+'/vendor';
        },
        async changeStatus(type){
            let data = this.exhData[this.$route.query.exh]
            let tagKeyArray = []
                data.tags.forEach((item,index)=>{
                    tagKeyArray.push(item.key)
                })
            let param = {
                signboardUrl: data.signboardUrl,
                coverUrl: data.coverUrl,
                title: data.title,
                introduction: data.introduction,
                tags: tagKeyArray, // JSON string array
                status: type // publish or draft
            }
            let res = await updateSellerExhData(param,data._id)
            return res
        },
        cloneMesh(){
            if(this.editingModel==null)return 
            const dateTime = Date.now();
            const timestamp = Math.floor(dateTime / 1000);
            let modelId = parseInt(this.editingModel.name.split('_')[1]);
            this.setMeshLine(false,this.editingModel);
            let newMesh = this.editingModel.clone(`${this.editingModel.name.split('_')[0]}_${modelId+timestamp}`);
            this.signalChange('positionX',newMesh.position.x - 1);
            this.setMeshLine(true,newMesh);
            this.models.push(newMesh);
            this.editingModel = newMesh;
            this.gizmoManager.attachToMesh(newMesh);
        },
        keySet(){
            let vm  = this
            window.addEventListener('keydown', function(e) {
                //e.preventDefault();
                e.stopPropagation();
                const elem = document.querySelector('.inputText');
                const body = document.body;
                const canvas = vm.canvas;
                let inputFocus = body != document.activeElement && canvas!= document.activeElement ? true:false;
                if (e.key === "Delete"||e.key ==="Backspace") {
                // code for enter
                    if(elem !== document.activeElement && !inputFocus){
                        vm.deleteModel()
                    }
                }
                if (e.key === "c"&&!vm.keyDown.c&&!vm.cloneFire&&!inputFocus) {
                    vm.keyDown.c = true;
                    vm.cloneMesh();
                    vm.cloneFire = true;
                }
            });
            window.addEventListener('keyup', function(e) {
                if (e.key === "c") {
                    vm.keyDown.c = false;
                    vm.cloneFire = false;
                }
                if (e.key === "Meta") {
                    vm.keyDown.command = false;
                }
            });
        },
        saveMessage(){
            this.editingModel.message = this.avatarMessage;
            this.avatarEditActive = false;
        }
    },
    async mounted() {
        this.init();
        this.initDrop();
        this.keySet();
    },
    destroyed(){
        if(this.engine) this.engine.stopRenderLoop();
    },
    watch:{
        bgc(newV,oldV){
            let sky = this.scene.getTransformNodeByName('hdrSkyBox')
            let thisMesh = this.scene.getMeshByName('playground')
            if(thisMesh!=null){
                thisMesh.dispose()
                sky.dispose()
                this.setPlayground()
            }
        },
        exhData(newV,oldV){
            this.getEditng()
        },
        colorSelect(newV,oldV){
            this.seletMaterial(0,0,'color')
        },
        vendor(newV,oldV){
            let vm = this
            if(newV._id){
                if(!window.localStorage.hasOwnProperty(`${vm.vendor._id}_firstUsed`)){
                    this.$refs.head.slideToggle = true
                    window.localStorage.setItem(`${vm.vendor._id}_firstUsed`, true);
                }
            }
        },
        sellerModel(){
            this.exhMoreToggle = [];
            this.sellerModel.forEach(element => {
                this.exhMoreToggle.push(false);
            });
        },
        sellershareModel(){
            this.exhMoreToggle = [];
            this.sellershareModel.forEach(element => {
                this.exhMoreToggle.push(false);
            });
        },
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/toolbar.scss';
@import '@/assets/scss/_variables.scss';
.exhEdit{
    position: relative;
    min-width: 900px;
    .header{
        position: absolute;
        width: 100%;
        top: 0;
        z-index: 99;
        height:75px;
        opacity: 1;
    }
    .main{
        position: relative;
        height: 100vh;
        // width: 100%;
        // height: 100%;
    }
    .zoomIn{
        position:absolute;
        left: 0.5%;
        bottom: 12%;
    }
    .zoomOut{
        position:absolute;
        left: 0.5%;
        bottom: 5%;
    }
    .rotate{
        position:absolute;
        left: 0.5%;
        bottom: 0%;
    }
    .editComponent{
                padding: 3% 10px 0;
                &:before{
                        content: "";
                        height: 1px;
                        left: -11px;
                        width: 106%;
                        background-color: #D9DAE5;
                        display: block;
                        position: absolute;
                        top: -8%;
                }
                h4{
                    margin-top: 0;
                    font-size: 12px;
                    text-align: left;
                    position: relative;
                }
                .componentImg{
                        background-color:#4D4D4D;
                        background-size: 89%;
                        padding-bottom: 49%;
                        border-radius: 4%;
                        background-position: 50% 75%;
                        background-repeat: no-repeat;
                        width: 60%;
                        border: 1px solid #E9E9E9;
                        margin: 0 auto 8%;
                }
                h3{
                    //font-size: 24px;
                    font-size: $font*24;
                }
                p{
                    width:100%;
                    color:$gray;
                    &:nth-of-type(1){
                        text-align: left;
                        //font-size: 15px;
                        font-size: 12px;
                        margin: 0 auto 5px;
                    }
                    &:nth-of-type(2){
                        //font-size: 12px;
                        font-size: $font*12;
                        margin: 0 21%;
                        background-color: #F3F3F3;
                        line-height: 2.4;
                        margin: 0 auto 5%;
                    }
                }
                .arrow{
                    background-image:url('~@/assets/img/icon/arrow.svg') ;
                    background-size: 100%;
                    padding-bottom: 4%;
                    width: 9px;
                    display: inline-block;
                    background-position: center;
                    position: absolute;
                    right: 2%;
                    transform:rotate(180deg);
                }
                &.active{
                    .arrow{
                        transform:rotate(0deg);
                    }
                }
                .videoLink{
                    p{
                        font-size: 12px;
                        text-align: left;
                        margin: 0 auto 5px;
                    }
                }
                .colorMateriral{
                    position:relative;
                    p{
                        font-size: 12px;
                        text-align: left;
                        margin: 0 auto 5%;
                        font-weight: 600;
                    }
                }
                .mediaPreview{
                    width: 100%;
                    margin:0 auto;
                    margin-bottom: 18%;
                    >p{
                        margin-bottom:10px;
                    }
                    p{
                        width: 100% !important;
                    }
                    .uploadWrap{
                        
                        p{
                            text-align: center !important;
                        }
                        #uploadModelImg{
                            opacity: 0;
                            position: absolute;
                            z-index: -1;
                        }
                        #uploadModelImg1{
                            opacity: 0;
                            position: absolute;
                            z-index: -1;
                        }
                    }
                    .uploadBtn{
                        border-radius: 2px;
                        color:#fff;
                        line-height: 2.8;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            width:100%;
                        }
                        span{
                            width: 6%;
                            padding-bottom: 7%;
                            margin-right: 5%;
                            background-image: url('~@/assets/img/icon/upload.svg');
                            background-size:100%; 
                            display: inline-block;
                            width: 6%;                         
                        }
                    }
                    .uploadImgPreview{
                        margin-bottom: 6%;
                        .preview{
                            width: 100%;
                            padding-bottom: 10%;
                            margin-bottom: 6%;
                            background-size: 100%;
                            background-position: center center;
                            margin-bottom: 6%;
                            padding-bottom: 56%;
                            background-color: black;
                        }
                        p{
                            text-align: right !important;
                            display: flex;
                            justify-content: flex-end;
                            align-items: flex-end;
                        }
                        span{
                            width: 5%;
                            padding-bottom:5%;
                            background-image: url('~@/assets/img/icon/replace.svg');
                            background-size:100%; 
                            display: inline-block;  
                            margin-right: 3%;                         
                        }
                    }
                }
                input{
                    width: 100%;
                    font-size: 12px;
                    //font-size: $font*12;
                    line-height: 2;
                    border-radius: 3px;
                    margin: 0 auto 5%;
                }
                .colorWrap{
                    display: flex;
                    width: 60%;
                    margin: 0 auto 15%;
                    flex-wrap:wrap;
                    width:227px;
                    .item{
                        padding-bottom: 9%;
                        padding-bottom:27px;
                        background-size: cover;
                        height: 33px;
                        cursor:pointer;
                        //width: 11%;
                        margin-right: 6.8%;
                        background-size:cover;
                        width: 33px;
                        margin-bottom:10px;
                        &:nth-of-type(5n+1){
                            margin-left:0 !important;
                        }
                        &:nth-of-type(5n){
                            margin-right:0 !important;
                        }
                        &.active{
                            border: 2px solid #4454FF;
                        }
                    }
                    .addColor{
                        cursor: pointer;
                        border-radius: 5px;
                        border: 4px solid #E9E9E9;
                        margin-right: 7%;
                        background-size: cover;
                        width: 33px;
                        margin-bottom: 5px;
                        position: relative;
                        padding: 0;
                        padding-bottom:27px;
                        margin-left: 1%;
                        label{
                            background-color: rgb(219, 204, 204);
                            width: 100%;
                            display: inline-block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                        }
                        input{
                            position: absolute;
                            inset: 0px;
                            visibility: hidden;
                        }
                        &.active{
                            border: 3px solid #FFB800;
                        }
                    }
                }
                .btnWrap{
                    margin-bottom:5%;
                    .cancel{
                        font-size: $font*20;
                        line-height: 2.5;
                        border-radius: 8px;
                        margin-right: 4%;
                        width: 34%;;
                    }
                    .submit{
                            width: 34%;
                            font-size: $font*20;
                            border-radius: 8px;
                            line-height: 2.5;
                            margin-left: 4%;
                    }
                }
                .avatarEdit{
                    width: 240px;
                    h4{
                        margin-bottom: 10px;
                    }
                    p{
                        &:nth-of-type(1){
                            margin: 0 auto 10px;
                        }
                        &:nth-of-type(2){
                            background-color: inherit;
                            text-align: left;
                            position: relative;
                            span{
                                position:absolute;
                                right:5%;
                                color:$purple;
                            }
                        }
                    }
                    textarea{
                            border:none;
                    }
                    &.active{
                        p{
                            &:nth-of-type(2){
                                span{
                                    color:#B1AEAE;
                                }
                            }
                        }
                        textarea{
                                border: 1px solid black;
                        }
                    }
                    textarea{
                        resize:none;
                        font-size:12px;
                        height: 16vh;
                        margin-bottom: 5%;
                        width:100%;
                        padding:5px;
                    }
                    .btnWrap{
                        margin-bottom: 5%;
                        display: flex;
                        justify-content: flex-end;
                        button{
                            width: 56px;
                            line-height: 1.5;
                            border-radius: 3px;
                            font-size:12px;
                        }
                    }
                }
            }
    .saveAlert{
        position:fixed;
        background-color: rgba($color: #000000, $alpha: 0.5);
        top:0;
        left:0;
        right:0;
        bottom:0;
        .container{
            background-color: #fff;
            width: 23%;
            position: absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            padding: 0.8% 0 1.8%;
            border-radius: 11px;
            border:2px #6F52ED solid;
            min-width: 330px;
        }
        img{
            width:3%;
            margin:0 3% 5% auto;
            display: block;
        }
        .txt{
            width: 80%;
            margin:0 auto;
        }
        h4{
            font-size:20*$font;
            color:#444444;
            margin-bottom: 12%;
            padding: 0 6%;
        }
        h5{
            font-size: 17*$font;
            color:#444444;
            margin-bottom: 6%;
            font-weight: 400;
            margin-top: 0;
            text-align: left;
            &:nth-of-type(1){
                margin-bottom: 2%;
            }
            span{
                color:#FF4CAD;
            }
        }
        button{
            font-size: 17*$font;
            line-height: 2.3;
            width: 25%;
            border-radius: 7px;
            display: block;
            margin: 5% auto 0;
            min-width: 67px;
        }
    }

    .sideBar{
        position: absolute;
        left:0;
        top:0;
        height: 100%;
        display: flex;
        //width: 5.3%;
        width: 52px;
        //min-width: 84px;
        .sideMenu{
            background-color: #fff;
            padding-top: 50px;
            min-height: 100%;
            //width: 100px;
            width: 100%;
            position: relative;
            //min-width: 80px;
            padding-top: 90px;
            ul{
                li{
                    font-size: 12*$font;
                    color:#fff;
                    font-weight: 400;
                    text-align: center;
                    //height: 63px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    //margin-bottom: 20px;
                    margin: 0 auto 28%;
                    width:36px;
                    height:36px;
                    border-radius: 100%;
                    position:relative;
                    @include for-size(tablet-landscape-only) {
                        font-size: 12px;
                    }
                    img{
                        display: block;
                        //width: 62%;
                        &:nth-of-type(1){
                            display: block;
                        }
                        &:nth-of-type(2),&:nth-of-type(3){
                            display: none;
                        }
                        &:nth-of-type(3){
                            position:absolute;
                            right: -25%;
                            transform: translate(100%,-50%);
                            top: 50%;
                        }
                    }
                    &.active,&:hover{
                        background-color: #D8DBFF;
                        color:#6F52ED;
                        img{
                            &:nth-of-type(2){
                                display: block;
                            }
                            &:nth-of-type(1){
                                display: none;
                            }
                        }
                    }
                    &:hover{
                        img{
                            &:nth-of-type(3){
                                display: block;
                            }
                        }
                    }
                    &:nth-of-type(4){
                        img{
                            width: 64%;
                            &:nth-of-type(3){
                                width:auto;
                            }
                        }   
                    }
                    &:nth-of-type(6){
                        img{
                            width: 53%;
                            &:nth-of-type(3){
                                width:auto;
                            }
                        }
                    }
                }
            }
        }
        .sideMenuConent{
            position: absolute;
            background-color:#fff;
            //width: 452px;
            width: 452%;
            min-height: 100%;
            //left: -452px;
            left:100%;
            margin-top: 75px;
            min-width: 350px;
            border-radius: 0 28px 28px 0;
            &.close{
                //transform: translateX(402px);
                left:-575%;
            }
            .title{
                display: flex;
                //font-size: 20px;
                font-size: $font*20;
                letter-spacing: 1px;
                text-align: center;
                position: relative;
                height:50px;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                padding-top: 4%;
                .arrow{
                    width: 50px;
                    height:50px;
                    position: absolute;
                    background-color:#6F52ED;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left:0;
                    cursor: pointer;
                    .arrowrotate{
                        transform: rotate(180deg);
                        transform-origin: center;
                    }
                }
            }
            .menu{
                padding: 0px 30px;
                position: relative;
                height: 74vh;
                overflow: auto;
                .filter{
                    display: block;
                    width: 176px;
                }
                .container{
                    display: flex;
                    padding-top: 30px;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    .item{
                        //width: 114px;
                        text-align: center;
                        //margin-right:14px;
                        margin-bottom: 20px;
                        font-size: 12px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 50%;
                        position: relative;
                        &.active{
                            outline:1px solid #4454FF;
                        }
                        .img{
                            width: 114px;
                            border-radius: 10px;
                            border:1px solid #E9E9E9;
                            //padding-bottom: 100px;
                            padding-bottom: 112px;
                            background-size: contain;
                            background-color:#fff;
                            background-repeat: no-repeat;
                            background-position:center center;
                        }
                    }
                }
                .uploadWrap{
                    width: 100%;
                    position: relative;
                    cursor: pointer;
                    img{
                        width: 100%;
                        &:nth-of-type(2){
                            display: none;
                            position: absolute;
                            top:0;
                            left:0;
                            right:0;
                            bottom:0;
                        }
                    }
                    &:hover,&.dragover{
                        img{
                            &:nth-of-type(1){
                                visibility: hidden;
                            }
                            &:nth-of-type(2){
                                display: block;
                            }
                        }
                    }
                    p{
                        color: #FF4CAD;
                        position: absolute;
                        bottom: 2%;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 88%;
                        font-size: $font*16;
                        @include for-size(tablet-landscape-only) {
                            font-size: 12px;
                        }
                    }
            label{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                width: 100%;
                height: 100%;
            }
            svg{
                display: none;
            }
        }
                .customerEmpty{
                    img{
                        width:50%;
                        margin: 0 auto 8%;
                    }
                    p{
                        font-size: $font*14;
                        color:#5D5D5D;
                        span{
                            color:$purple;
                            text-decoration: underline;
                        }
                    }
                }
            }
            button{
                background-color: #4454FF;
                font-size: 14px;
                border-radius: 4px;
                width:211px;
                height:36px;
                text-align: center;
                border:none;
                box-shadow: 1.7px 5.83px 19.7px 0 rgba(0,0,0,.15);
                color:#fff;
            }
            .editComponent{
                padding-top:10%;
                height: 85vh;
                overflow: auto;
                padding-bottom: 5%;
                .componentImg{
                        background-size: 100%;
                        //padding-bottom: 49%;
                        padding-bottom: 59.5%;
                        border-radius: 4%;
                        background-position: 50% 75%;
                        background-repeat: no-repeat;
                        width: 60%;
                        border: 1px solid #E9E9E9;
                        margin: 0 auto 8%;
                }
                h3{
                    //font-size: 24px;
                    font-size: $font*24;
                }
                p{
                    width:58%;
                    color:$gray;
                    &:nth-of-type(1){
                        text-align: left;
                        //font-size: 15px;
                        font-size: $font*15;
                        margin: 0 auto 5px;
                    }
                    &:nth-of-type(2){
                        //font-size: 12px;
                        font-size: $font*12;
                        margin: 0 21%;
                        background-color: #F3F3F3;
                        line-height: 2.4;
                        margin: 0 auto 5%;
                    }
                    &:nth-of-type(3){
                        //font-size: 15px;
                        font-size: $font*15;
                        text-align: left;
                        margin: 0 auto 5px;
                    }
                    &:nth-of-type(4){
                        //font-size: 15px;
                        font-size: $font*15;
                        text-align: left;
                        margin: 0 auto 5%;
                    }
                }
                input{
                    width: 58%;
                    //font-size: 12px;
                    font-size: $font*12;
                    line-height: 2;
                    border-radius: 5px;
                    margin: 0 auto 5%;
                }
                // .colorWrap{
                //     display: flex;
                //     width: 60%;
                //     margin: 0 auto 15%;
                //     .item{
                //         padding-bottom: 9%;
                //         padding-bottom:27px;
                //         cursor:pointer;
                //         //width: 11%;
                //         border-radius: 5px;
                //         border: 4px solid #E9E9E9;
                //         margin-right:7%;
                //         //background-size:100%;
                //         background-size:cover;
                //         width: 33px;
                //         &:nth-of-type(4n){
                //             margin-right:0 !important;
                //         }
                //         &.active{
                //             border: 3px solid #FFB800;
                //         }
                //     }
                //     .addColor{
                //         background-image:url('~@/assets/img/icon/add.svg');
                //         //padding-bottom: 9%;
                //         padding-bottom: 27px;
                //         // width: 11%;
                //         width: 33px;
                //         border-radius: 5px;
                //         border: 4px solid #E9E9E9;
                //         background-size: 63%;
                //         background-position: center;
                //         background-repeat: no-repeat;
                //     }
                // }
                .btnWrap{
                    margin-bottom:5%;
                    .cancel{
                        font-size: $font*20;
                        line-height: 2.5;
                        border-radius: 8px;
                        margin-right: 4%;
                        width: 34%;;
                    }
                    .submit{
                            width: 34%;
                            font-size: $font*20;
                            border-radius: 8px;
                            line-height: 2.5;
                            margin-left: 4%;
                    }
                }
            }
        }
    }
    .setBgc{
                position: absolute;
                left:20px;
                top: 97px;
                width: 250px;
                border: none;
                left:50%;
                transform: translateX(-50%);
                ::v-deep .el-select{
                    width: 100%;
                    &:before{
                        content:'Background：';
                        position: absolute;
                        z-index: 5;
                        font-size: 14px;
                        left: 16px;
                        top: 50%;
                        transform: translateY(-50%);
                        color:#282828;
                    }
                }
                input{
                    font-size: 20px;
                    border: none;
                    font-weight: 400;
                }
                ::v-deep .el-select .el-input .el-select__caret{
                    color:#757575;
                    top: 2px;
                    font-weight: 900;
                }
                ::v-deep .el-select-dropdown__wrap{
                    margin-bottom: 0;
                }
            }
        .bgcSelect ::v-deep .el-select .el-input.is-focus .el-input__inner{
            padding: 0 20px 0 50px;
        }
        .bgcSelect ::v-deep  .el-select-dropdown__item{
            padding: 0 20px 0 50px;
        }
        .setBgc ::v-deep .el-input--suffix .el-input__inner{
            padding: 0 15px 0 110px;
        }

    .modelController{
        position: absolute;
        right: 0;
        top: 75px;
        background-color: #fff;
        height: calc(100% - 75px);
        overflow-y: auto;
        overflow-x: hidden;
        ul{
            width: 240px;
            overflow: hidden;
            display: flex;
            margin-bottom: 3%;
            border-bottom: 1px solid #D9DAE5;
            li{
                width: 25%;
                height: 48px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    &:nth-of-type(2){
                        display: none;
                    }
                }
                &:hover{
                    img{
                        &:nth-of-type(1){
                            display: none;
                        }
                        &:nth-of-type(2){
                            display: block;
                        }
                    }
                }
                &.active{
                    img{
                        &:nth-of-type(1){
                            display: none;
                        }
                        &:nth-of-type(2){
                            display: block;
                            padding: 5px;
                            border-radius: 5px;
                            border: 1px solid #4454FF;
                            background-color: #E8E8E8;
                        }
                    }
                }
            }
        }
    }
    .editIinput{
        //top: 240px;
        overflow: hidden;
        width: 240px;
        background-color: #fff;
        padding: 0 10px 7%;
        position:relative;
        &:before{
            content:'';
            height:1px;
            left: 0;
            width: 100%;
            background-color: #D9DAE5;
            display: block;
            position:absolute;
            bottom:0;
        }
        h4{
            font-size: 12px;
            display: flex;
            align-items: center;
            line-height: 2.2;
            color: #333333;
            position: relative;
            font-weight: 600;
            margin: 0;
        }
        // .cube{
        //     background-image:url('~@/assets/img/icon/cube.svg') ;
        //     background-size: 100%;
        //     padding-bottom: 4.5%;
        //     width: 11px;
        //     display: inline-block;
        //     margin-right: 14px;
        // }
        .arrow{
            background-image:url('~@/assets/img/icon/arrow.svg') ;
            background-size: 100%;
            padding-bottom: 4%;
            width: 9px;
            display: inline-block;
            background-position: center;
            position: absolute;
            right: 2%;
            transform:rotate(180deg);
        }
        &.active{
            .arrow{
                transform:rotate(0deg);
            }
        }
        h5,p{
            font-size: 12px;
            text-align: left;
        }
        h5{
            color:#333333;
            font-weight:400;
            margin: 20px 0 10px;
        }
        p{
            color:#282828;    
            margin-right:7px;
            margin-bottom: 0;
            margin-top: 0;
        }
        .editInputWrap{
            padding: 0 6% 7% 0;
        }
        .editInputContainer{
            display: flex;
            justify-content: space-between;
            .item{
                display: flex;
                width: 29%;
                align-items: center;
                input{
                    &:focus{
                        border-color:$purple;
                    }
                    width: 92%;
                    border-radius: inherit;
                    font-size:12px;
                    height: 21px;
                    border-radius: 2px;;
                }
            }
        }
    }
    .bg{
            position: fixed;
            top:0;
            bottom:0;
            right:0;
            left:0;
            z-index: 99;
        }
    .uploadModelPop{
            border-radius: 9px;
            overflow: hidden;
            //width: 32%;
            width: 346px;
            position: relative;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            background-color: #fff;
            //min-width: 400px;
            padding-bottom: 3%;
            @include blockShadow;
            h4{
                font-size: 16px;
                position:relative;
                line-height: 1.5;
                color:$gray;
                margin-top: 0;
                margin-bottom: 3%;
                font-weight: 500;
                // @include for-size(desktop-only) {
                //     font-size: 14px;
                // }
                span{
                    background-image:url('~@/assets/img/icon/crossW.svg');
                    background-size: 100%;
                    width: 3%;
                    padding-bottom: 3%;
                    position: absolute;
                    top: 50%;
                    right: 2%;
                    transform:translateY(-50%);
                    background-position: center;
                }
            }
            p{
                font-size:12px;
                margin: 0;
                line-height: 1.5;
                &:nth-of-type(2){
                    margin-bottom: 4%;
                }
            }
            >img{
                display:block;
                margin: 5px 5px 0 auto;
            }
            .container{
                display: flex;
                padding: 0 16%;
                margin-bottom: 6%;
                img{
                    display: none;
                    width: 45.2%;
                    margin-right: 4%;
                }
                .uploadModelImg{
                    width: 100%;
                    #upload-model-photo{
                        display:block;
                        width:100%;
                        // background-size: 100%;
                        background-size: cover;
                        padding-bottom: 47%;
                        cursor: pointer;
                        background-position: center;
                    }
                    #upload-model-input{
                        opacity: 0;
                        position: absolute;
                        z-index: -1;
                    }
                }
                .item{
                    h4{
                        font-size:$font*20;
                        background-color: inherit;
                        text-align: left;
                        margin-bottom: 0;
                        color:#282828;
                        line-height: 2;
                        margin-bottom: 7%;
                        @include for-size(desktop-only) {
                            font-size: 14px;
                        }
                    }
                    p{
                        font-size: 12px;
                        text-align: left;
                        margin-top: 4%;
                        margin-bottom: 0;
                    }
                }
            }
            .inputWrap{
                padding: 0 16%;
                width: 100%;
                margin-bottom: 4%;
                label{
                    font-size: $font*18;
                    display: block;
                    text-align: left;
                    margin-bottom: 5px;
                    font-weight:500;
                    @include for-size(desktop-only) {
                        font-size: 12px;
                    }
                }
                input{
                    font-size: $font*18;   
                    line-height: 2.5;
                    width: 100%;
                    border-color:$gray;
                    padding: 0 5px;
                    @include for-size(desktop-only) {
                        font-size: 12px;
                    }
                }
            }
            .btnWrap{
                padding: 0 16%;
                justify-content: space-between;
                display: flex;
                button{
                    font-size: $font*16;
                    width:46%;
                    margin:0;
                    line-height: 2.5;
                    @include blockShadow;
                    @include for-size(desktop-only) {
                        font-size: 12px;
                        border-radius: 7px;
                    }
                }
            }
        }
        .moreContainer{
                position:absolute;
                right:10px;
                height:16px;
                width:16px;
                display:flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                border-radius: 1px;
                bottom: 10px;
                &:hover,&.active{
                    background-color: #D8DBFF;
                }
                .dot{
                    width:2px;
                    height:2px;
                    border-radius:100%;
                    background-color: #B1AEAE;
                }
                .message{
                    position:absolute;
                    background-color: #FFFFFF;
                    border-radius:4px;
                    padding: 0px 9px;
                    width: 98px;
                    right: -5px;
                    bottom: 33px;
                    @include blockShadow;
                    .poly{
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 11.5px 13px 11.5px;
                        border-color: transparent transparent #FFFFFF transparent;
                        position:absolute;
                        transform: rotate(180deg);
                        right: 3px;
                        bottom: -12px;
                    }
                    p{
                        color:#333333;
                        text-align: left;
                        font-size:12px;
                        margin:9px 0;
                    }
                }
            }
}
#renderCanvas{
    outline: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}
</style>